import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { OpIamRequiredAction } from "../../generated/op-iam-admin/v1/model/opIamRequiredAction";
import { CredentialType as OpIamAdminCredentialType } from "../../generated/op-iam-admin/v1/model/credentialType";

@Injectable()
export class AccountRequiredActionApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/accounts`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  sendKeycloakActions(accountId: string,
    actions: Array<OpIamRequiredAction>,
  ): Observable<void> {

    let params = new HttpParams();
    actions.forEach(action => params = params.append("actions", action));
    const url: string = `${this.baseUrl}/${accountId}/saveAndSendKeycloakActions`;
    return this.httpClient.post<void>(url, null, { params: params }).pipe(
      this.catchHttpError(),
    );
  }

  getOtpCredentialTypes(accountId: string): Observable<OpIamAdminCredentialType[]> {
    const url: string = `${this.baseUrl}/${accountId}/credentialTypes`;
    return this.httpClient.get<OpIamAdminCredentialType[]>(url).pipe(
      this.catchHttpError(),
    );
  }

  deleteOtpCredentialTypeById(accountId: string, credentialTypeId): Observable<void> {
    const url: string = `${this.baseUrl}/${accountId}/credentialTypes/${credentialTypeId}`;
    return this.httpClient.delete<void>(url).pipe(
      this.catchHttpError(),
    );
  }

}
