import { Injectable } from "@angular/core";
import { UserData } from "./model/user-data";
import { HttpClient } from "@angular/common/http";
import { UserApiService } from "./op-iam-admin/user-api.service";
import { OrganisationApiService } from "./op-iam-admin/organisation-api.service";
import { AccountApiService } from "./op-iam-admin/account-api.service";
import { PersonApiService } from "./op-iam-admin/person-api.service";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { EMPTY, forkJoin, Observable, of } from "rxjs";
import { catchError, concatMap, map, switchMap } from "rxjs/operators";
import { User as OpIamAdminUser } from "../generated/op-iam-admin/v1/model/user";
import { Person as OpIamAdminPerson } from "../generated/op-iam-admin/v1/model/person";
import { Account as OpIamAdminAccount } from "../generated/op-iam-admin/v1/model/account";
import { UserDataTransformService } from "./user-data-transform.service";
import { Organisation as OpIamAdminOrganisation } from "../generated/op-iam-admin/v1/model/organisation";
import { MasterSystem } from "../generated/op-iam-admin/v1/model/masterSystem";

@Injectable()
export class UserDataAccessService {
  constructor(private httpClient: HttpClient,
    private userApiService: UserApiService,
    private organisationApiService: OrganisationApiService,
    private accountApiService: AccountApiService,
    private personApiService: PersonApiService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  createUserData(user: UserData): Observable<UserData> {
    const person = UserDataTransformService.toPersonData(user);
    return this.personApiService.createPerson(person).pipe(
      concatMap(res => {
        return this.createAccountAndLoadEmployer(user, res);
      }),
      catchError( () => {
        // reload
        setTimeout(() => {
          // trigger an active search to avoid a person will be created multiple times!
          let currentUrl = window.location.href;
          window.location.href = currentUrl.replace("/manage-user/new", "/manage-user/search");
        }, 10000);
        return EMPTY;
      }),
    );
  }

  private readonly preventForbiddenErrorWhenLoadingEmployee: boolean = true;

  searchUsersWithEmployer(email: string,
    searchAll: boolean,
    expandEmployerAddresses?: boolean): Observable<UserData[]> {
    return this.userApiService.searchUsers(email, searchAll).pipe(
      concatMap(users => {
        const userObservables: Observable<UserData>[] = [];

        for (let user of users) {
          const organisationId = UserDataTransformService.getDefaultCommonPerson(user)?.employerId;
          const employerObservable: Observable<UserData> = this.organisationApiService.getOrganisationByOrganisationId(
            organisationId,
            expandEmployerAddresses,
            this.preventForbiddenErrorWhenLoadingEmployee,
          ).pipe(
            map(employer =>
              UserDataTransformService.toUserData(user, employer)),
          );
          userObservables.push(employerObservable);
        }

        if (userObservables.length > 0) {
          return forkJoin(userObservables);
        } else {
          return of([]);
        }
      },
      ),
      catchError( () => {
        return of([]);
      }),
    );
  }

  updateUser(user: UserData, update: Partial<UserData>): Observable<UserData> {
    const expandEmployerAddresses = true;
    const personId = user?.userId;
    if (personId) {
      const accountObservable$ = update.language == undefined ? of(UserDataTransformService.toAccountData(user))
        : this.accountApiService.updateAccountLanguage(user.accountId, user.language);

      const personObservable$ = this.personApiService.updatePerson(
        personId,
        UserDataTransformService.toPersonData(update),
      );

      return forkJoin([personObservable$, accountObservable$]).pipe(
        concatMap(([person, account]: [OpIamAdminPerson, OpIamAdminAccount]) => {
          const opIamAdminUser: OpIamAdminUser = UserDataTransformService.toUser(account, person);
          // if the employer id does not change the reponse person does not contain the employerId
          const organisationId = user?.employer?.id;
          const employerObservable$ = this.organisationApiService.getOrganisationByOrganisationId(
            organisationId,
            expandEmployerAddresses,
            this.preventForbiddenErrorWhenLoadingEmployee,
          );
          // loadEmployer
          return forkJoin([of(opIamAdminUser), employerObservable$]);
        }),
        map(([updatedUser, employer]: [OpIamAdminUser, OpIamAdminOrganisation]) => {
          return UserDataTransformService.toUserData(updatedUser, employer);
        },
        ));
    }
  }

  createAccountAndLoadEmployer(user: UserData, person: OpIamAdminPerson): Observable<UserData> {
    // appends the employer address in the employer field
    const expandEmployerAddresses = true;

    return this.createPortalServicesCommonPersonIfNotExists(person).pipe(
      map((personWithCcid: OpIamAdminPerson) => {
        person = personWithCcid;
        user.commonCustomer = { commonPersonId: personWithCcid?.commonPersonId };
        return UserDataTransformService.toAccountData(user);
      }),
      concatMap((accountData: OpIamAdminAccount) =>  this.accountApiService.createAccount(accountData)),
      concatMap((createdOpIamAdminAccount: OpIamAdminAccount) => {
        const opIamAdminUser: OpIamAdminUser = UserDataTransformService.toUser(createdOpIamAdminAccount, person);
        const organisationId = UserDataTransformService.getDefaultCommonPerson(opIamAdminUser)?.employerId;
        const employerObservable$ = this.organisationApiService.getOrganisationByOrganisationId(
          organisationId,
          expandEmployerAddresses,
          this.preventForbiddenErrorWhenLoadingEmployee,
        );
          // loadEmployer
        return forkJoin([of(opIamAdminUser), employerObservable$]);
      }),
      map(([loadedUser, employer]: [OpIamAdminUser, OpIamAdminOrganisation]) => {
        return UserDataTransformService.toUserData(loadedUser, employer);
      },
      ));
  }

  getEmptyUserObject(): Observable<UserData> {
    const masterSystemUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/mastersystem/defaultmastersystem`;
    return this.httpClient.get<string>(masterSystemUrl).pipe(
      map(defaultMasterSystem =>
        UserDataTransformService.getEmptyUserDto(defaultMasterSystem as MasterSystem),
      ),
    );
  }

  private createPortalServicesCommonPersonIfNotExists(person: OpIamAdminPerson): Observable<OpIamAdminPerson> {
    if (person != null && person.commonPersonId == null) {
      return this.personApiService.createPortalServicesPerson(person.personId).pipe(
        // we need to reload the person because it got a new ccid now
        switchMap(() => this.personApiService.getPersonById(person.personId)),
      );
    } else {
      return of(person);
    }
  }
}
