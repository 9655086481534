import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { CommonPerson as OpIamAdminCommonPerson } from "../../generated/op-iam-admin/v1/model/commonPerson";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class CommonPersonApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/commonpersons`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getCommonPersonByCommonPersonId(commonPersonId: number, suppressErrorHandling: boolean = false)
    : Observable<OpIamAdminCommonPerson> {
    const url = `${this.baseUrl}/${commonPersonId}`;
    const commonPersonObservable$ = this.httpClient.get<OpIamAdminCommonPerson>(url);

    if (suppressErrorHandling) {
      return commonPersonObservable$;
    }

    return commonPersonObservable$.pipe(super.catchHttpError());
  }
}
