import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { concatMap, finalize, map, startWith } from "rxjs/operators";
import { ResettableDataStore } from "./resettable-data-store";
import { UserDataService } from "./user-data.service";
import { AdminPermissions as OpIamAdminPermissions } from "../generated/op-iam-admin/v1/model/adminPermissions";
import { Service as OpIamAdminService } from "../generated/op-iam-admin/v1/model/service";
import { AdminPermissionApiService } from "./op-iam-admin/admin-permission-api.service";
import { AdminPermissionOrganisation } from "./model/admin-permission-organisation";
import { CustomCommonOrganisation as OpIamAdminCustomCommonOrganisation } from "../generated/op-iam-admin/v1/model/customCommonOrganisation";
import { CommonOrganisationServiceCodes } from "../generated/op-iam-admin/v1/model/commonOrganisationServiceCodes";
import { AdminPermissionObject } from "./model/admin-permission-object";
import { AdminPermissionService } from "./admin-permission.service";
import { Organisation as OpIamAdminOrganisation } from "../generated/op-iam-admin/v1/model/organisation";
import { AccountApiService } from "./op-iam-admin/account-api.service";
import { selectOrganisationsWithServices } from "../store/app.selector";
import { Store } from "@ngrx/store";
import { AppState } from "../store/app.state";

const EMPTY_PERMISSIONS_DTO: OpIamAdminPermissions = {
  konzernAdmin: false,
  brandAdmin: false,
  serviceAdminPermissions: [],
  superUserPermissions: [],
};

@Injectable()
export class UserPermissionService extends ResettableDataStore<OpIamAdminPermissions> {
  private saving: boolean = false;

  private loading: boolean = false;

  private loaded: boolean = false;

  private _adminPermissionOrganisations: AdminPermissionOrganisation[] = [];

  constructor(private userDataService: UserDataService,
    private adminPermissionService: AdminPermissionService,
    private adminPermissionApiService: AdminPermissionApiService,
    private opAccountService: AccountApiService,
    private store: Store<AppState>,
  ) {
    super();

    this.userDataService.getData$().pipe(
      startWith(null),
    ).subscribe(() => {
      this.setData(EMPTY_PERMISSIONS_DTO);
      this.loaded = false;
    });
  }

  getData(): OpIamAdminPermissions {
    const adminAuthorized = this.adminPermissionService.isKonzernOrBrandOrServiceAdmin;
    if (adminAuthorized && !this.loaded && !this.loading) {
      this.loading = true;
      this.load()
        .catch(() => EMPTY_PERMISSIONS_DTO)
        .then((adminPermissions: OpIamAdminPermissions) => {
          this.setData(adminPermissions);
          this.initData(adminPermissions);
        });
    }

    return super.getData();
  }

  savePermissions(
    serviceAdminPermissionsToAssign: AdminPermissionObject[],
    serviceAdminPermissionsToRemove: AdminPermissionObject[],
    superUserPermissionsToAssign: AdminPermissionObject[],
    superUserPermissionsToRemove: AdminPermissionObject[],
    customerAdvisorServicesToAssign: OpIamAdminService[],
    customerAdvisorServicesToRemove: OpIamAdminService[],
  ): Observable<any> {
    this.saving = true;
    const user = this.userDataService.getData();
    const accountId = user.accountId;

    const $assignServiceAdminArray: Observable<void>[] = serviceAdminPermissionsToAssign.map(permission =>
      this.adminPermissionApiService.assignServiceAdminPermission(
        accountId,
        permission.serviceCode),
    );
    const $removeServiceAdminArray: Observable<void>[] = serviceAdminPermissionsToRemove.map(permission =>
      this.adminPermissionApiService.removeServiceAdminPermission(
        accountId,
        permission.serviceCode),
    );

    const $assignSuperUserArray: Observable<void>[] = superUserPermissionsToAssign.map(permission =>
      this.adminPermissionApiService.assignSuperUserPermission(
        accountId,
        permission.commonOrganisationId,
        permission.serviceCode),
    );
    const $removeSuperUserArray: Observable<void>[] = superUserPermissionsToRemove.map(permission =>
      this.adminPermissionApiService.removeSuperUserPermission(
        accountId,
        permission.commonOrganisationId,
        permission.serviceCode),
    );

    const $assignCustomerAdvisorServicesArray: Observable<void>[] = customerAdvisorServicesToAssign
      .map(serviceToAssign =>
        this.opAccountService.assignGlobalServiceToAccount(accountId, serviceToAssign.serviceId));
    const $removeCustomerAdvisorServicesArray: Observable<void>[] = customerAdvisorServicesToRemove
      .map(serviceToRemove =>
        this.opAccountService.removeGlobalServiceFromAccount(accountId, serviceToRemove.serviceId));

    const $updatePermissions: Observable<void>[] = [
      ...$assignServiceAdminArray,
      ...$removeServiceAdminArray,
      ...$assignSuperUserArray,
      ...$removeSuperUserArray,
      ...$assignCustomerAdvisorServicesArray,
      ...$removeCustomerAdvisorServicesArray,
    ];

    return forkJoin($updatePermissions).pipe(
      finalize(() => {
        this.saving = false;
        this.loaded = false; // to enforce reloading the data
      }),
      concatMap(() => {
        const hasAnyAdminPermissions: boolean = [...serviceAdminPermissionsToAssign, ...serviceAdminPermissionsToRemove,
          ...superUserPermissionsToAssign, ...superUserPermissionsToRemove].length > 0;
        if (hasAnyAdminPermissions) {
          return this.adminPermissionApiService.syncOpAdminService(accountId);
        }
        return of([]);
      }),
    );
  }

  private async load(): Promise<OpIamAdminPermissions> {
    return this.userDataService.getData().accountId != null ?
      this.adminPermissionApiService.getUserAdminPermissions(this.userDataService.getData().accountId).toPromise() :
      Promise.resolve(EMPTY_PERMISSIONS_DTO);
  }

  isLoaded(): boolean {
    return this.loaded;
  }

  resetLoadedData(): void {
    this.loaded = false;
    this.loading = false;
  }

  isSaving(): boolean {
    return this.saving;
  }

  get adminPermissionOrganisations(): AdminPermissionOrganisation[] {
    return this._adminPermissionOrganisations;
  }

  get canSeeDefaultOrganisations(): boolean {
    return this.adminPermissionService.isKonzernOrBrandOrServiceAdmin;
  }

  private initData(permissions: OpIamAdminPermissions): void {
    const superUserPermissionsFromDto = permissions.superUserPermissions ?? [];

    this.store.select(selectOrganisationsWithServices)
      .pipe(
        map(organisations => organisations.filter(organisation => organisation?.services?.length > 0)),
      )
      .subscribe((customCommonOrganisations: OpIamAdminCustomCommonOrganisation[]) => {
        this.loading = false;
        this.loaded = true;
        this._adminPermissionOrganisations
            = customCommonOrganisations.map(
            org => this.toAdminPermissionOrganisation(org, superUserPermissionsFromDto),
          );
      }, () => {
        this.loading = false;
        this.loaded = true;
      });
  }

  private toAdminPermissionOrganisation(customCommonOrganisation: OpIamAdminCustomCommonOrganisation,
    superUserPermissionsFromDto: Array<CommonOrganisationServiceCodes>,
  ): AdminPermissionOrganisation {
    customCommonOrganisation?.organisations?.sort(
      (org1: OpIamAdminOrganisation, org2: OpIamAdminOrganisation) =>
        org1.masterSystem.localeCompare(org2.masterSystem),
    );
    let organisationDisplayName = customCommonOrganisation.organisations[0].name;
    if (!!customCommonOrganisation.organisations[0].nameAddon) {
      organisationDisplayName += " " + customCommonOrganisation.organisations[0].nameAddon;
    }
    return {
      commonOrganisationId: customCommonOrganisation.commonOrganisationId,
      displayName: organisationDisplayName,
      serviceCodes: Object.assign({}, ...customCommonOrganisation.services
        .filter(service => !!service.code)
        .map(service =>
          ({
            [service.code]: superUserPermissionsFromDto.length > 0
                        && superUserPermissionsFromDto.some(
                          permission => permission.serviceCodes.indexOf(service.code) > -1
                            && permission.commonOrganisationId === customCommonOrganisation.commonOrganisationId),
          }))),
      commonCustomer: null, // in oneportal-admin-backend was always null for superUserPermissions
    };
  }
}
