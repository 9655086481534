import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable, of } from "rxjs";
import { Service as OpIamAdminService } from "../../generated/op-iam-admin/v1/model/service";
import { Account as OpIamAdminAccount } from "../../generated/op-iam-admin/v1/model/account";
import { AdminPermissionService } from "../admin-permission.service";

@Injectable()
export class CommonOrganisationApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/commonorganisations`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService,
    private adminPermissionService: AdminPermissionService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getAllCommonOrganisationIdsWithAnyService(): Observable<number[]> {
    const url: string = `${this.baseUrl}/search/byAnyService`;
    if (this.adminPermissionService.isKonzernOrBrandOrServiceAdmin) {
      return this.httpClient.get<number[]>(url).pipe(
        this.catchHttpError(),
      );
    } else {
      return of([]);
    }
  }

  getOrganisationServicesAndBundles(commonOrganisationId: number): Observable<OpIamAdminService[]> {
    const url: string = `${this.baseUrl}/${commonOrganisationId}/services`;
    // see documentation for resolveBundles-Logic under
    // https://bkwag.atlassian.net/wiki/spaces/AD/pages/311953342/Solution+Design+Bundle+Berechtigungen+DSV-265
    // and https://bkwag.atlassian.net/wiki/spaces/AD/pages/311951940/OP+IAM+-+Umgang+mit+Bundles+Services+und+Permissions+in+Frontends
    const params = new HttpParams().set("resolveBundles", String(false));
    return this.httpClient.get<OpIamAdminService[]>(url, { params }).pipe(
      this.catchHttpError(),
    );
  }

  assignServiceToExistingCommonOrganisation(commonOrganisationId: number, serviceId: number): Observable<void> {
    const url: string = `${this.baseUrl}/${commonOrganisationId}/services/${serviceId}`;
    return this.httpClient.post<void>(url, null).pipe(
      this.catchHttpError(),
    );
  }

  removeServiceFromCommonOrganisation(commonOrganisationId: number, serviceId: number): Observable<void> {
    const url: string = `${this.baseUrl}/${commonOrganisationId}/services/${serviceId}`;
    return this.httpClient.delete<void>(url).pipe(
      this.catchHttpError(),
    );
  }

  getAssignedAccountsOfCommonOrganisation(commonOrganisationId: number): Observable<OpIamAdminAccount[]> {
    const url: string = `${this.baseUrl}/${commonOrganisationId}/accounts`;
    return this.httpClient.get<OpIamAdminAccount[]>(url).pipe(
      this.catchHttpError(),
    );
  }

}
