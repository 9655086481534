export enum OrganisationSearchTableColumns {
  NAME = "name",
  ADDRESS = "address",
  COMMON_ORGANISATION_ID = "portal-services-commonid",
  MSDYNAMICS_ID = "msdynamicsId",
  CRM_NETZE_ID = "crmNetzeId",
  MASTERSYSTEM = "mastersystem",
  SAP_ISU_GP_NUMBER = "sapisuGpNumber",
  SAP_ERP_GP_NUMBER = "saperpGpNumber",
  EICX_CODE = "eicxCode",
  ACTIVE = "active",
}
