/**
 * OP IAM Admin Backend
 * OP IAM Admin Backend - used for Portal Administration (webapp and external backends)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CustomerTypeEnum = 'B2B' | 'B2C' | 'B2B_B2C';

export const CustomerTypeEnum = {
    B2_B: 'B2B' as CustomerTypeEnum,
    B2_C: 'B2C' as CustomerTypeEnum,
    B2_B_B2_C: 'B2B_B2C' as CustomerTypeEnum
};

