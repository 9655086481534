import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Service as OpIamService, Service as OpIamAdminService } from "../../generated/op-iam-admin/v1/model/service";
import { ServiceTypeEnum } from "../../generated/op-iam-admin/v1/model/serviceTypeEnum";
import { CustomerTypeEnum } from "../../generated/op-iam-admin/v1/model/customerTypeEnum";

@Injectable()
export class ServiceApiService {

  private readonly serviceBaseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/services`;

  private readonly roleNameB2BAdmin: string = "ROLE_B2B_ADMIN";

  /**
   * add services which are in the 'allowedServices' group, but should not be visible!
   * @private
   */
  private readonly IGNORE_SERVICES: String[] = ["ROLE_OP_SUPER_USER"];

  private readonly roleNameOpCustomerAdvisor: string = "ROLE_OP_CUSTOMER_ADVISOR";

  private cachedOpServices: Observable<OpIamService[]>;

  constructor(private bfcConfigurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  getAllServices(): Observable<OpIamAdminService[]> {
    if (!this.cachedOpServices) {
      this.cachedOpServices = this.httpClient.get<OpIamAdminService[]>(this.serviceBaseUrl);
    }
    return this.cachedOpServices;
  }

  getAllNonB2BAdminServices(): Observable<OpIamAdminService[]> {
    return this.getAllServices().pipe(
      map((services: OpIamAdminService[]) => services
        .filter(service => !service.code.startsWith(this.roleNameB2BAdmin) &&
          !this.IGNORE_SERVICES.includes(service.code))
        .sort(ServiceApiService.compareServices.bind(this))),
    );
  }

  getAllGlobalB2BServices(): Observable<OpIamAdminService[]> {
    return this.getAllServices().pipe(
      map((filterServices: OpIamAdminService[]) => filterServices
        .filter(service => !service.code.startsWith(this.roleNameB2BAdmin) &&
          !service.code.startsWith(this.roleNameOpCustomerAdvisor) &&
          service.serviceType === ServiceTypeEnum.GLOBAL &&
            (service.customerType === CustomerTypeEnum.B2_B || service.customerType === CustomerTypeEnum.B2_B_B2_C),
        ).sort(ServiceApiService.compareServices.bind(this))),
    );
  }

  getCustomerAdvisorServices(): Observable<OpIamAdminService[]> {
    return this.getAllServices().pipe(
      map((filterServices: OpIamAdminService[]) => filterServices
        .filter(service => service.code.startsWith(this.roleNameOpCustomerAdvisor) &&
          (service.customerType === CustomerTypeEnum.B2_B || service.customerType === CustomerTypeEnum.B2_B_B2_C),
        ).sort(ServiceApiService.compareServices.bind(this))),
    );
  }

  getServicesByServiceCodes(serviceCodes: string[]): Observable<OpIamAdminService[]> {
    return this.getAllServices().pipe(
      map((services: OpIamAdminService[]) => services.filter(service => serviceCodes.indexOf(service.code) >= 0)
        .sort(ServiceApiService.compareServices.bind(this))),
    );
  }

  static compareServices(s1: OpIamAdminService, s2: OpIamAdminService) {
    if (s1.name > s2.name) {
      return 1;
    } else if (s1.name < s2.name) {
      return -1;
    } else if (s1.code > s2.code) {
      return 1;
    } else if (s1.code < s2.code) {
      return -1;
    }
    return 0;
  }

}

