import { Person as OpIamAdminPerson } from "../generated/op-iam-admin/v1/model/person";
import { User as OpIamAdminUser } from "../generated/op-iam-admin/v1/model/user";
import { Organisation as OpIamAdminOrganisation } from "../generated/op-iam-admin/v1/model/organisation";
import { Account as OpIamAdminAccount } from "../generated/op-iam-admin/v1/model/account";
import { UserData } from "./model/user-data";
import { Employer } from "./model/employer";
import { MasterSystem as OpIamAdminMasterSystem } from "../generated/op-iam-admin/v1/model/masterSystem";
import { Salutation as OpIamAdminSalutation } from "../generated/op-iam-admin/v1/model/salutation";

export class UserDataTransformService {

  static getEmptyUserDto(masterSystem: OpIamAdminMasterSystem): UserData {
    return {
      accountId: "",
      enabled: true,
      loginEmail: "",
      email: "",
      salutation: "",
      firstName: "",
      lastName: "",
      language: "",
      userId: null,
      masterSystem: masterSystem,
      phoneNumber: "",
      sapIsuAccountId: undefined,
      defaultOrganisationId: undefined,
      employer: undefined,
      commonCustomer: undefined,
    } as UserData;
  }

  static toUserData(user: OpIamAdminUser, employerOrganisation: OpIamAdminOrganisation): UserData {
    const defaultPerson = this.getDefaultCommonPerson(user);

    let sapIsuAccountId;
    if (defaultPerson?.masterSystem === OpIamAdminMasterSystem.SAPISU
        || defaultPerson?.masterSystem === OpIamAdminMasterSystem.SAPISUA) {
      sapIsuAccountId = defaultPerson.masterId;
    }

    return {
      accountId: user?.account?.accountId,
      loginEmail: user?.account?.loginEmail,
      language: UserDataTransformService.getLanguage(user),
      enabled: user?.account?.enabled,
      userId: defaultPerson?.personId,
      masterSystem: defaultPerson?.masterSystem,
      email: defaultPerson?.email,
      salutation: defaultPerson?.salutation,
      firstName: defaultPerson?.firstName,
      emailVerified: user?.account?.emailVerified,
      lastName: defaultPerson?.lastName,
      phoneNumber: defaultPerson?.phone,
      sapIsuAccountId,
      defaultOrganisationId: user?.account?.defaultCommonOrganisationId,
      employer: this.getEmployerFromOrganisation(employerOrganisation),
      commonCustomer: {
        commonPersonId: user?.commonPerson?.commonPersonId,
        persons: user?.commonPerson?.persons,
      },
    };
  }

  private static getEmployerFromOrganisation(opIamAdminOrganisation: OpIamAdminOrganisation): Employer {
    if (opIamAdminOrganisation) {
      return {
        id: opIamAdminOrganisation.organisationId,
        name: opIamAdminOrganisation.name,
        address: opIamAdminOrganisation.addresses?.[0],
      } as Employer;
    }
    return undefined;
  }

  static toAccountData(user: Partial<UserData>): OpIamAdminAccount {

    return {
      accountId: user?.accountId,
      commonPersonId: user?.commonCustomer?.commonPersonId,
      defaultCommonOrganisationId: user?.defaultOrganisationId,
      enabled: user?.enabled,
      language: user?.language,
      loginEmail: user?.email,
    };
  }

  static toUser(opIamAdminAccount: OpIamAdminAccount, person: OpIamAdminPerson): OpIamAdminUser {
    return  {
      account: opIamAdminAccount,
      commonPerson: {
        commonPersonId: person?.commonPersonId,
        persons: [person],
      },
    };
  }

  static toPersonData(user: Partial<UserData>): OpIamAdminPerson {
    return {
      email: user?.email,
      commonPersonId: user?.commonCustomer?.commonPersonId,
      personId: user?.userId,
      employerId: user?.employer?.id,
      firstName: user?.firstName,
      language: user?.language,
      lastName: user?.lastName,
      masterSystem: user?.masterSystem as OpIamAdminMasterSystem,
      phone: user?.phoneNumber,
      salutation: user?.salutation as OpIamAdminSalutation,
    };
  }

  static getDefaultCommonPerson(user: OpIamAdminUser): OpIamAdminPerson {
    return user?.commonPerson?.persons?.[0];
  }

  /**
     * if user.account empty, get language of user.person
     * @param user
     * @private
     */
  private static getLanguage(user: OpIamAdminUser): string {
    return (user?.account) ? user.account.language : UserDataTransformService.getDefaultCommonPerson(user)?.language;
  }
}
