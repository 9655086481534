import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector,
    private bfcNotificationService: BfcNotificationService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  handleError(error: any): void {
    const bfcTranslationService = this.injector.get(BfcTranslationService);

    if (this.bfcConfigurationService.environment.stage === "local") {
      // we already lost a lot of time because we cannot see error in console!
      // eslint-disable-next-line no-console
      console.error(error);
    }

    this.bfcNotificationService.showNotification({
      type: BfcNotificationType.ERROR,
      message: bfcTranslationService.translate("GENERAL_ERROR"),
    });
  }
}
