import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class ExportApiService {

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  triggerB2BExportToDocHub(): Observable<void> {
    return this.httpClient.post<void>(this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl + "/v1/export/dochub", {});
  }
}
