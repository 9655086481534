import { AppState } from "./app.state";
import { createSelector } from "@ngrx/store";
import { CustomCommonOrganisation as OpIamAdminCustomCommonOrganisation } from "../generated/op-iam-admin/v1/model/customCommonOrganisation";
import { Service as OpIamAdminService } from "../generated/op-iam-admin/v1/model/service";

export const selectOpContext = createSelector(
  (state: AppState) => state.opContext,
  (opContext: string) => opContext,
);

export const selectOrganisationsWithServices = createSelector(
  (state: AppState) => state.organisationsWithServices,
  (organisationsWithServices: OpIamAdminCustomCommonOrganisation[]) => organisationsWithServices,
);

export const selectLoadingOrganisationsWithServices = createSelector(
  (state: AppState) => state.loadingOrganisationsWithServices,
  (loading: boolean) => loading,
);

export const selectBundleGroups = createSelector(
  (state: AppState) => state.bundleGroups,
  (bundleGroups: OpIamAdminService[]) => bundleGroups,
);