import { OpAdminAppConfiguration } from "../../configuration";

export const GENERAL_ENV_HINNI: OpAdminAppConfiguration = {

  globalNavSettings: {
    enableAccountMenu: false,
  },
  forceOtpRole: "ROLE_FORCE_OTP",
  maintenanceSettings: {
    overviewDateFormat: "DD.MM.YYYY HH:mm",
    shownDateFormat: "YYYY-MM-DD",
    shownTimeFormat: "HH:mm",
    storedDateFormat: "YYYY-MM-DDTHH:mm:ssZ",
  },
};
