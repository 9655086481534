import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable()
export class AppGuard implements CanActivate {
  constructor(private router: Router,
    private bfcAuthenticationService: BfcAuthenticationService) {
  }

  canActivate(): boolean {

    if (this.bfcAuthenticationService.hasRealmRole("ROLE_ONEPORTAL_ADMIN")) {
      this.router.navigate(["/manage-user"]);
    } else if (this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_MAINTENANCE")) {
      this.router.navigate(["/manage-maintenance"]);
    } else if (this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_SERVICECONFIG")) {
      this.router.navigate(["/manage-service-configuration"]);
    } else if (this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_CC_MAINTENANCE")) {
      this.router.navigate(["/manage-cc-maintenance"]);
    } else if (this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_NEWS")) {
      this.router.navigate(["/manage-news"]);
    } else if (this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_EXPORT")) {
      this.router.navigate(["/b2b-export"]);
    } else {
      this.router.navigate(["/unauthorized"]);
    }
    return false;
  }
}
