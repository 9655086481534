import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BfcAuthenticationInterceptor } from "@bfl/components/authentication";
import { NgModule } from "@angular/core";

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
}
