import { Injectable } from "@angular/core";
import { AdminPermissions as OpIamAdminPermissions } from "../../generated/op-iam-admin/v1/model/adminPermissions";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { EMPTY, Observable } from "rxjs";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable()
export class AdminPermissionApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/adminpermissions`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcAuthenticationService: BfcAuthenticationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getMyAdminPermissions(): Observable<OpIamAdminPermissions> {
    if (!this.bfcAuthenticationService.hasRealmRole("ROLE_ONEPORTAL_ADMIN")) {
      return EMPTY;
    }
    return this.httpClient.get<OpIamAdminPermissions>(this.baseUrl).pipe(
      this.catchHttpError(),
    );
  }

  getUserAdminPermissions(accountId: string): Observable<OpIamAdminPermissions> {
    const url = `${this.baseUrl}/${accountId}`;
    return this.httpClient.get<OpIamAdminPermissions>(url).pipe(
      this.catchHttpError(),
    );
  }

  assignServiceAdminPermission(accountId: string, serviceCode: string): Observable<void> {
    const url = this.getServiceAdminUpdateUrl(accountId, serviceCode);
    return this.httpClient.post<void>(url, {}).pipe(
      this.catchHttpError(),
    );
  }

  removeServiceAdminPermission(accountId: string, serviceCode: string): Observable<void> {
    const url = this.getServiceAdminUpdateUrl(accountId, serviceCode);
    return this.httpClient.delete<void>(url).pipe(
      this.catchHttpError(),
    );
  }

  assignSuperUserPermission(accountId: string, commonOrganisationId: number, serviceCode: string): Observable<void> {
    const url = this.getSuperUserUpdateUrl(accountId, commonOrganisationId, serviceCode);
    return this.httpClient.post<void>(url, {}).pipe(
      this.catchHttpError(),
    );
  }

  removeSuperUserPermission(accountId: string, commonOrganisationId: number, serviceCode: string): Observable<void> {
    const url = this.getSuperUserUpdateUrl(accountId, commonOrganisationId, serviceCode);
    return this.httpClient.delete<void>(url).pipe(
      this.catchHttpError(),
    );
  }

  syncOpAdminService(accountId: string): Observable<void> {
    const url = `${this.baseUrl}/${accountId}/syncopadmin`;
    return this.httpClient.post<void>(url, {}).pipe(
      this.catchHttpError(),
    );
  }

  private getServiceAdminUpdateUrl(accountId: string, serviceCode: string): string {
    return `${this.baseUrl}/serviceadmins/${accountId}/services/${serviceCode}`;
  }

  private getSuperUserUpdateUrl(accountId: string, commonOrganisationId: number, serviceCode: string): string {
    return `${this.baseUrl}/superusers/${accountId}/commonorganisations/${commonOrganisationId}/services/${serviceCode}`;
  }
}
