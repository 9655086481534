import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { AdminPermissionService } from "../admin-permission.service";

@Injectable()
export class InitializerProvider {

  constructor(private bfcTranslateService: BfcTranslationService,
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
    private adminPermissionService: AdminPermissionService) {
  }

  initialize() {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );

    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    if (globalNavElements.length === 1) {
      globalNavElements[0].setAttribute("service-name", JSON.stringify(this.bfcTranslateService.translate("SERVICE_NAME")));
      if (this.bfcConfigurationService.configuration.globalNavSettings?.enableGlobalNavigationApi !== undefined &&
                !this.bfcConfigurationService.configuration.globalNavSettings?.enableGlobalNavigationApi) {
        globalNavElements[0].setAttribute("global-navigation-api-url", "");
      }
      if (this.bfcConfigurationService.configuration.globalNavSettings?.enableContactLink !== undefined) {
        globalNavElements[0].setAttribute("enable-contact-link", JSON.stringify(this.bfcConfigurationService.configuration.globalNavSettings.enableContactLink));
      }
      if (this.bfcConfigurationService.configuration.globalNavSettings?.enablePromoSlots !== undefined) {
        globalNavElements[0].setAttribute("enable-promo-slots", JSON.stringify(this.bfcConfigurationService.configuration.globalNavSettings.enablePromoSlots));
      }
      if (this.bfcConfigurationService.configuration.globalNavSettings?.enableAccountMenu !== undefined) {
        globalNavElements[0].setAttribute("enable-account-menu", JSON.stringify(this.bfcConfigurationService.configuration.globalNavSettings.enableAccountMenu));
      }
    } else {
      throw (new Error("too many or no global navigation element found"));
    }

    // init permission service
    return this.adminPermissionService.initialize();
  }

}
