export const GENERAL_BKW_ENV = {
  googleTagManagerContainerId: "GTM-N4VBJZH",
  tradingRoles: ["ROLE_BKW_CLUB", "ROLE_DAVE_DV", "ROLE_DAVE_VIEW", "ROLE_DAVE_FORECAST", "ROLE_TANGENTO"],
  belvisRole: "ROLE_BELVIS",
  forceOtpRole: "ROLE_FORCE_OTP",
  isTemporaryRole: "ROLE_IS_TEMPORARY",
  allowEditMasterSystemOnUserCreation: true,
  maintenanceSettings: {
    overviewDateFormat: "DD.MM.YYYY HH:mm",
    shownDateFormat: "YYYY-MM-DD",
    shownTimeFormat: "HH:mm",
    storedDateFormat: "YYYY-MM-DDTHH:mm:ssZ",
  },
};
