import { Injectable } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable, of } from "rxjs";
import { concatMap, finalize, map, tap } from "rxjs/operators";
import { ResettableDataStore } from "./resettable-data-store";
import { UserData } from "./model/user-data";
import { UserDataAccessService } from "./user-data-access.service";
import { UserDataTransformService } from "./user-data-transform.service";
import { UserApiService } from "./op-iam-admin/user-api.service";
import {
  CommonPersonDeletionResult as OpIamCommonPersonDeletionResult,
} from "../generated/op-iam-admin/v1/model/commonPersonDeletionResult";
import { Person as OpIamAdminPerson } from "../generated/op-iam-admin/v1/model/person";
import { PersonApiService } from "./op-iam-admin/person-api.service";

@Injectable()
export class UserDataService extends ResettableDataStore<UserData> {
  private saving: boolean = false;

  constructor(private userDataAccessService: UserDataAccessService,
    private userApiService: UserApiService,
    private personApiService: PersonApiService,
    private bfcTranslationService: BfcTranslationService,
    private bfcNotificationService: BfcNotificationService) {
    super();
  }

  saveChanges(): Observable<UserData> {
    this.saving = true;
    return this.userDataAccessService.updateUser(
      this.getData(),
      this.getUserPatchDiff(),
    ).pipe(
      finalize(() => this.saving = false),
      map((userData: UserData) => {
        this.setData(userData);

        this.bfcNotificationService.showNotification({
          type: BfcNotificationType.SUCCESS,
          message: this.bfcTranslationService.translate("MANAGE_USER.DETAILS.SUCCESS_NOTIFICATION"),
        });

        return userData;
      }),
    );
  }

  /**
     * Creates a OpIamAccount and a OpIamPerson with the current values of the UserData retrieved with this.getData()
     */
  createUser(): Observable<UserData> {
    this.saving = true;
    const formUserData = this.getData();

    return this.userDataAccessService.createUserData(formUserData).pipe(
      finalize(() => this.saving = false),
      map((user: UserData) => {
        this.setData(user);
        if (!!formUserData.employer?.id && !user.employer?.id) { // employer was sent to backend but could not be set
          this.bfcNotificationService.showNotification({
            type: BfcNotificationType.WARNING,
            message: this.bfcTranslationService.translate("MANAGE_USER.CREATE.SUCCESS_NOTIFICATION_EMPLOYER_NOT_SET"),
          });
        } else {
          this.bfcNotificationService.showNotification({
            type: BfcNotificationType.SUCCESS,
            message: this.bfcTranslationService.translate("MANAGE_USER.CREATE.SUCCESS_NOTIFICATION"),
          });
        }

        return user;
      }),
    );
  }

  /**
     * Creates a OpIamAccount linked to the already existing
   * common person is the UserData (userData.commonCustomer.commonPersonId)
     */
  createAccount(updatePersonNeeded: boolean): Observable<UserData> {
    this.saving = true;
    const userData = this.getData();
    const person: OpIamAdminPerson = UserDataTransformService.toPersonData(userData);

    return this.updatePersonDataBeforeCreatingAccount(person, updatePersonNeeded).pipe(
      concatMap((updatedPerson: OpIamAdminPerson) => {
        return this.userDataAccessService.createAccountAndLoadEmployer(userData, updatedPerson);
      }),
      finalize(() => this.saving = false),
      map((res: UserData) => {
        this.setData(res);

        this.bfcNotificationService.showNotification({
          type: BfcNotificationType.SUCCESS,
          message: this.bfcTranslationService.translate("MANAGE_USER.DETAILS.SUCCESS_NOTIFICATION"),
        });
        return userData;
      }),
    );
  }

  private updatePersonDataBeforeCreatingAccount(person: OpIamAdminPerson, updateNeeded: boolean):
  Observable<OpIamAdminPerson> {
    if (person?.personId != null && !!updateNeeded) {
      return this.personApiService.updatePerson(person.personId, person);
    } else {
      return of(person);
    }
  }

  deleteUser(reason: string): Observable<OpIamCommonPersonDeletionResult> {
    return this.userApiService.deleteUserByAccountId(this.getData().accountId, reason).pipe(
      tap(() => {
        this.setData(null);

        this.bfcNotificationService.showNotification({
          type: BfcNotificationType.SUCCESS,
          message: this.bfcTranslationService.translate("MANAGE_USER.DELETE.SUCCESS_NOTIFICATION"),
        });
      }),
    );
  }

  initializeUserCreation(email: string): void {
    this.setData(null);
    this.userDataAccessService.getEmptyUserObject().subscribe((user: UserData) => {
      user.email = email;
      user.loginEmail = email;
      user.enabled = true;
      this.setData(user);
    });
  }

  isSaving() {
    return this.saving;
  }

  /**
     * Creates a Partial<UserData> object which contains changed values which should be updated
     */
  getUserPatchDiff(): Partial<UserData> {
    const updatedUser = this.getData();
    const oldUser = this.getUntouchedData();
    const result: Partial<UserData> = {};

    if (oldUser?.loginEmail !== updatedUser?.loginEmail) {
      result.loginEmail = updatedUser?.loginEmail;
    }
    if (oldUser?.email !== updatedUser?.email) {
      result.email = updatedUser?.email;
    }
    if (oldUser?.salutation !== updatedUser?.salutation) {
      result.salutation = updatedUser?.salutation;
    }
    if (oldUser?.firstName !== updatedUser?.firstName) {
      result.firstName = updatedUser?.firstName;
    }
    if (oldUser?.lastName !== updatedUser?.lastName) {
      result.lastName = updatedUser?.lastName;
    }
    if (oldUser?.language !== updatedUser?.language) {
      result.language = updatedUser?.language;
    }
    if (oldUser?.phoneNumber !== updatedUser?.phoneNumber) {
      result.phoneNumber = updatedUser?.phoneNumber;
    }
    if (oldUser?.defaultOrganisationId !== updatedUser?.defaultOrganisationId) {
      result.defaultOrganisationId = updatedUser?.defaultOrganisationId;
    }
    if (oldUser?.employer?.id !== updatedUser?.employer?.id) {
      result.employer = updatedUser?.employer;
    }
    if (oldUser?.employer?.id !== updatedUser?.employer?.id) {
      result.employer = updatedUser?.employer;
    }

    return result;
  }
}
