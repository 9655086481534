import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { UserData } from "./model/user-data";

@Injectable()
export class BelvisActivationService {

  private belvisApiUrl: string;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
    this.belvisApiUrl = this.bfcConfigurationService.configuration.backendSettings.opEdmApiUrl + "/belvis/admin/users/";
  }

  postCredentials(user: UserData, belvisCredentials: BelvisCredentials): Observable<any> {
    return this.httpClient.post<any>(this.belvisApiUrl + user.userId + "/belvis-credentials", belvisCredentials);
  }

  getCredentials(user: UserData): Observable<any> {
    return this.httpClient.get<any>(this.belvisApiUrl + user.userId + "/belvis-credentials/");
  }

  verifyCredentials(user: UserData): Observable<boolean> {
    return this.httpClient.get<boolean>(this.belvisApiUrl + user.userId + "/belvis-credentials/verify");
  }

}

export class BelvisCredentials {
  username: string;

  password: string;
}
