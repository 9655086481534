import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable } from "rxjs";
import {
  CustomCommonOrganisation as OpIamAdminCustomCommonOrganisation,
} from "../../generated/op-iam-admin/v1/model/customCommonOrganisation";

@Injectable()
export class CustomCommonOrganisationApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/customcommonorganisations`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getAllCustomCommonOrganisationsWithAnyService(): Observable<OpIamAdminCustomCommonOrganisation[]> {
    const url: string = `${this.baseUrl}/search/getAllCustomCommonOrganisationsWithAnyService`;
    const params = new HttpParams().set("resolveBundles", String(false));
    return this.httpClient.get<OpIamAdminCustomCommonOrganisation[]>(url, { params }).pipe(
      this.catchHttpError(),
    );
  }

  getAccountsCustomCommonOrganisationsWithAnyService(
    accountId: string,
  ): Observable<OpIamAdminCustomCommonOrganisation[]> {
    const url: string = `${this.baseUrl}/search/getAllCustomCommonOrganisationsWithAnyServiceByAccountId`;
    const params = new HttpParams().set("resolveBundles", String(false))
      .set("accountId", accountId);
    return this.httpClient.get<OpIamAdminCustomCommonOrganisation[]>(url, { params }).pipe(
      this.catchHttpError(),
    );
  }

}
