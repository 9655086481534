import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { User as OpIamAdminUser } from "../../generated/op-iam-admin/v1/model/user";
import { CommonPersonDeletionResult as OpIamCommonPersonDeletionResult } from "../../generated/op-iam-admin/v1/model/commonPersonDeletionResult";

@Injectable()
export class UserApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/users`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  searchUsers(email: string, searchAll: boolean): Observable<OpIamAdminUser[]> {
    const params = new URLSearchParams();
    params.set("email", email);
    params.set("searchAll", String(searchAll));

    return this.httpClient.get<OpIamAdminUser[]>(`${this.baseUrl}?${params.toString()}`)
      .pipe(
        this.catchHttpError(),
      );
  }

  deleteUserByAccountId(accountId: string, reason: string): Observable<OpIamCommonPersonDeletionResult> {
    const params = new URLSearchParams();
    params.set("reason", reason);
    const url = `${this.baseUrl}/${accountId}?${params.toString()}`;

    return this.httpClient.delete(url).pipe(
      this.catchHttpError(),
    );
  }
}