/**
 * OP IAM Admin Backend
 * OP IAM Admin Backend - used for Portal Administration (webapp and external backends)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ServiceTypeEnum = 'GLOBAL' | 'OPADMIN' | 'ORGANISATION' | 'SERVICE_BUNDLE' | 'SERVICE_PERMISSION' | 'BUNDLE_GROUP';

export const ServiceTypeEnum = {
    GLOBAL: 'GLOBAL' as ServiceTypeEnum,
    OPADMIN: 'OPADMIN' as ServiceTypeEnum,
    ORGANISATION: 'ORGANISATION' as ServiceTypeEnum,
    SERVICE_BUNDLE: 'SERVICE_BUNDLE' as ServiceTypeEnum,
    SERVICE_PERMISSION: 'SERVICE_PERMISSION' as ServiceTypeEnum,
    BUNDLE_GROUP: 'BUNDLE_GROUP' as ServiceTypeEnum
};

