import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ServiceConfiguration as OpIamAdminServiceConfiguration } from "../../generated/op-iam-admin/v1/model/serviceConfiguration";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class ServiceConfigurationApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/serviceconfigurations`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getServiceConfigurations(): Observable<OpIamAdminServiceConfiguration[]> {
    return this.httpClient.get<OpIamAdminServiceConfiguration[]>(this.baseUrl)
      .pipe(
        this.catchHttpError(),
      );
  }

  getServiceConfiguration(id: string): Observable<OpIamAdminServiceConfiguration> {
    return this.httpClient.get<OpIamAdminServiceConfiguration>(`${this.baseUrl}/${id}`)
      .pipe(
        this.catchHttpError(),
      );
  }

  deleteServiceConfiguration(id: string): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}/${id}`)
      .pipe(
        this.catchHttpError(),
      );
  }

  updateServiceConfiguration(id: string,
    serviceConfig: OpIamAdminServiceConfiguration): Observable<OpIamAdminServiceConfiguration> {
    return this.httpClient.put(`${this.baseUrl}/${id}`, serviceConfig)
      .pipe(
        this.catchHttpError(),
      );
  }

  createServiceConfiguration(
    serviceConfig: OpIamAdminServiceConfiguration,
  ): Observable<OpIamAdminServiceConfiguration> {
    return this.httpClient.post(this.baseUrl, serviceConfig)
      .pipe(
        this.catchHttpError(),
      );
  }
}
