import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from "@angular/core";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BelvisActivationService } from "./belvis-activation.service";
import { GlobalErrorHandler } from "./global-error-handler";
import { initalizerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { AdminPermissionService } from "./admin-permission.service";
import { ServiceBundleService } from "./op-iam-admin/service-bundle.service";
import { UserDataService } from "./user-data.service";
import { UserPermissionService } from "./user-permission.service";
import { UserSearchService } from "./user-search.service";
import { CommonPersonApiService } from "./op-iam-admin/common-person-api.service";
import { AccountApiService } from "./op-iam-admin/account-api.service";
import { UserApiService } from "./op-iam-admin/user-api.service";
import { OrganisationApiService } from "./op-iam-admin/organisation-api.service";
import { PersonApiService } from "./op-iam-admin/person-api.service";
import { UserDataAccessService } from "./user-data-access.service";
import { ExportApiService } from "./op-iam-admin/export-api.service";
import { CustomCommonOrganisationApiService } from "./op-iam-admin/custom-common-organisation-api.service";
import { AdminPermissionApiService } from "./op-iam-admin/admin-permission-api.service";
import { ServiceApiService } from "./op-iam-admin/service-api.service";
import { ServiceConfigurationApiService } from "./op-iam-admin/service-configuration-api.service";
import { AccountRequiredActionApiService } from "./op-iam-admin/account-required-action-api.service";
import { CommonOrganisationApiService } from "./op-iam-admin/common-organisation-api.service";
import { MaintenanceDateHelperService } from "./maintenance-date-helper.service";
import { BundleGroupApiService } from "./op-iam-admin/bundle-group-api.service";

@NgModule({
  imports: [
    BfcGoogleAnalyticsModule,
    BfcNotificationModule,
    BfcDialogModule,
  ],
  providers: [
    UserDataService,
    AdminPermissionService,
    AdminPermissionApiService,
    ServiceConfigurationApiService,
    CommonPersonApiService,
    AccountApiService,
    AccountRequiredActionApiService,
    UserDataAccessService,
    UserApiService,
    PersonApiService,
    ServiceApiService,
    OrganisationApiService,
    ExportApiService,
    CustomCommonOrganisationApiService,
    CommonOrganisationApiService,
    BelvisActivationService,
    UserPermissionService,
    UserSearchService,
    MaintenanceDateHelperService,
    ServiceBundleService,
    BundleGroupApiService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    InitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider, AdminPermissionService],
      multi: true,
    },

  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${this.constructor.name} has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
