import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { HttpClientModule } from "@angular/common/http";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { translations } from "./config/translations/translations";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { configuration } from "./config/configuration";
import { Router } from "@angular/router";
import { CoreModule } from "./core/core.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { OverlayModule } from "@angular/cdk/overlay";
import { AppGuard } from "./app.guard";
import { UserPermissionService } from "./core/user-permission.service";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { StoreModule } from "@ngrx/store";
import { reducers } from "./store/app.reducer";
import { BfcSinglePageLayoutLargeModule } from "@bfl/components/single-page-layout-large";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./store/app.effects";
/**
 * code below can be enabled by uncomment the line, to locally use devtools for ngRxStore
 */
//import { StoreDevtoolsModule } from "@ngrx/store-devtools";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    SharedModule,
    BfcTranslationModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    CoreModule,
    BfcSinglePageLayoutModule,
    HttpClientModule,
    OverlayModule,
    BfcSinglePageLayoutLargeModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AppEffects]),
    /**
     * code below can be enabled by uncomment the line, to locally use devtools for ngRxStore
     */
    //StoreDevtoolsModule.instrument({ maxAge: 25 }),
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    AppGuard,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
  constructor(private router: Router,
    private userPermissionsService: UserPermissionService) {
    this.router.events.subscribe(() => {
      this.userPermissionsService.reset();
    });
  }
}
