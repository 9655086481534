import { Injectable } from "@angular/core";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { Observable, of } from "rxjs";
import {
  Organisation as OpIamAdminOrganisation,
} from "../../generated/op-iam-admin/v1/model/organisation";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import {
  PagedModelEntityModelOrganisation as OpIamAdminPagedModelEntityModelOrganisation,
} from "../../generated/op-iam-admin/v1/model/pagedModelEntityModelOrganisation";
import { PageMetadata } from "../../generated/op-iam-admin/v1/model/pageMetadata";
import { Sort } from "@angular/material/sort";
import { OrganisationSearchTableColumns } from "../../manage-organisation/search/organisation-search-table-columns.enum";
import { Address as OpIamAdminAddress } from "../../generated/op-iam-admin/v1/model/address";
import { AdminPermissionService } from "../admin-permission.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class OrganisationApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/organisations`;

  private readonly defaultPageSize: number = 150;

  private readonly foreignKeySearchPrefix: string = "foreignkey:";

  private readonly activeParallelOperationMHID: boolean = true; // TODO: remove this code, when DSV-2598 is done!

  readonly defaultSorting: string = "name,asc";

  readonly masterSystemSorting: string = "masterSystem,asc";

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService,
    private adminPermissionService: AdminPermissionService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getOrganisationByOrganisationId(organisationId?: number,
    expandAddresses?: boolean, preventForbiddenError?: boolean): Observable<OpIamAdminOrganisation> {
    if (organisationId == null) {
      return of(null);
    }

    const params = new HttpParams().set("expandAddresses", String(!!expandAddresses));

    const url = `${this.baseUrl}/${organisationId}`;
    if (this.adminPermissionService.isAnyAdmin) {
      return this.httpClient.get<OpIamAdminOrganisation>(url, { params })
        .pipe(
          catchError((error: unknown) => {
            if (preventForbiddenError && (error as HttpErrorResponse)?.status == 403) {
              // in case we have no permission e.g. SuperUser it should only show an empty field instead of an error
              return of(null);
            } else {
              throw error;
            }
          },
          ),
          this.catchHttpError(),
        );
    } else {
      return of(null);
    }
  }

  searchEmployer(employerName: string, masterSystem: string): Observable<OpIamAdminPagedModelEntityModelOrganisation> {
    const params = { "filter": `mastersystem eq '${masterSystem}' and displayname like '*${employerName}*'`,
      "expandAddresses": "true",
      "size": this.defaultPageSize };
    return this.httpClient.get(this.baseUrl, { params });
  }

  searchOrganisations(pageFilter: HttpParams): Observable<OpIamAdminPagedModelEntityModelOrganisation> {
    if (this.adminPermissionService.isKonzernOrBrandOrServiceAdmin) {
      return this.httpClient.get<OpIamAdminPagedModelEntityModelOrganisation>(this.baseUrl, {
        params: pageFilter,
      }).pipe(
        this.catchHttpError(),
      );
    } else {
      return of(null);
    }
  }

  preparePageFilterToHttpParams(filterByOption: OrganisationSearchTableColumns,
    filterByValue: string, page?: PageMetadata, sort?: Sort): HttpParams {
    let httpParams = new HttpParams();

    if (page) {
      httpParams = httpParams.set("page", String(page.number));
      httpParams = httpParams.set("size", String(page.size));
    } else {
      httpParams = httpParams.set("size", String(this.defaultPageSize));
    }

    if (sort) {
      httpParams = httpParams.set("sort", this.getSortString(sort));
    } else {
      httpParams = httpParams.set("sort", this.defaultSorting);
    }
    // add second sort by masterSystem
    httpParams = httpParams.append("sort", this.masterSystemSorting);

    // Bsp: ?filter=(displayname='BKW Energie AG')
    if (filterByOption && filterByValue) {
      httpParams = httpParams.set("filter", this.getFilterString(filterByOption, filterByValue));
      httpParams = httpParams.set("expandAddresses", "true");
    }

    return httpParams;
  }

  /**
   * Formates searchstrings for the backend out of a Sort object.
   *
   * For independent values:
   * Sort {active: "sortValue", direction: "asc"} -> "sortValue,asc"
   *
   * For foreignKeys the prefix "foreignkey:" is added:
   * Sort {active: "eicxCode", direction: "asc"} -> "foreignkey:eicx,asc"
   * @param sort
   * @private
   */
  private getSortString(sort: Sort): string {
    const standardSortString: string = `${OrganisationApiService.getSearchKeyMapping(sort.active)},${sort.direction}`;
    if (OrganisationApiService.isForeignKey(sort.active)) {
      return `${this.foreignKeySearchPrefix}${standardSortString}`;
    } else {
      return standardSortString;
    }
  }

  private static getSearchKeyMapping(value: string): string {
    switch (value) {
      case OrganisationSearchTableColumns.EICX_CODE:
        return "eicx";
      case OrganisationSearchTableColumns.MSDYNAMICS_ID:
        return "msdynamics-accountnumber";
      case OrganisationSearchTableColumns.SAP_ISU_GP_NUMBER:
        return "sapisu";
      case OrganisationSearchTableColumns.SAP_ERP_GP_NUMBER:
        return "saperp";
      default:
        return value;
    }
  }

  private static isForeignKey(value: string): boolean {
    switch (value) {
      case OrganisationSearchTableColumns.EICX_CODE:
      case OrganisationSearchTableColumns.MSDYNAMICS_ID:
      case OrganisationSearchTableColumns.SAP_ISU_GP_NUMBER:
      case OrganisationSearchTableColumns.SAP_ERP_GP_NUMBER:
      case OrganisationSearchTableColumns.COMMON_ORGANISATION_ID:
        return true;
      default:
        return false;
    }
  }

  createOrganisation(organisation: OpIamAdminOrganisation): Observable<OpIamAdminOrganisation> {
    return this.httpClient.post<OpIamAdminOrganisation>(this.baseUrl, organisation).pipe(
      this.catchHttpError(),
    );
  }

  /**
   * activates an organisation in portal-services, when it doesn't have a commonOrganisationId yet.
   * Don't activate it, when it already has a ccid!!
   * for details see https://bkwiki.bkw-fmb.ch/display/AD/Merging+in+OP+IAM+nach+MHID-Migration
   * @param organisationId
   */
  createPortalServicesOrganisation(organisationId: number): Observable<OpIamAdminOrganisation> {
    const url = `${this.baseUrl}/${organisationId}/portalServicesCommonOrganisations`;

    return this.httpClient.post<OpIamAdminOrganisation>(url, {}).pipe(
      this.catchHttpError(),
    );
  }

  postAddressToOrganisation(organisationId: number, address: OpIamAdminAddress): Observable<OpIamAdminAddress> {
    const url: string = `${this.baseUrl}/${organisationId}/addresses`;
    return this.httpClient.post<OpIamAdminAddress>(url, address).pipe(
      this.catchHttpError(),
    );
  }

  private getFilterString(filterByOption: OrganisationSearchTableColumns, filterByValue: string): string {

    switch (filterByOption) {
      case OrganisationSearchTableColumns.NAME:
        // if you're using an exact search, you needed to put your search in quotes -> "my query"
        const isExactSearch = filterByValue[0] == "\"" && filterByValue[filterByValue.length - 1] == "\"";
        if (isExactSearch) {
          // if you're using quotes, we transform it to query language of endpoint -> replace quotes by *
          return `displayname like '*${filterByValue.slice(1, -1)}*'`;
        } else {
          //all of the search terms must be present - order is not important
          const searchWords: string[] = filterByValue.split(" ");
          // if you have multiple words like 'bkw energie ag', this will concat the correct query string with AND:
          // eg:  displayname like '*bkw*' and displayname like '*energie*' and displayname like '*ag*'
          const reducer = (accumulator, currentValue) => `${accumulator} displayname like '*${currentValue}*' and `;
          return searchWords.reduce(reducer, "").slice(0, -5);
        }
      case OrganisationSearchTableColumns.COMMON_ORGANISATION_ID:
        if (isNaN(parseInt(filterByValue))) {
          return;
        }

        if (!!this.activeParallelOperationMHID) {
          return `foreignKeys having (value eq '${parseInt(filterByValue)}')`;
        }
        return `foreignKeys having (name eq 'portal-services-commonid' and value eq '${parseInt(filterByValue)}')`;
      case OrganisationSearchTableColumns.EICX_CODE:
        return `foreignKeys having (name eq 'eicx' and value eq '${filterByValue}')`;
      case OrganisationSearchTableColumns.SAP_ISU_GP_NUMBER:
        return `mastersystem eq 'sapisu' and masterid eq '${filterByValue}'`;
      case OrganisationSearchTableColumns.SAP_ERP_GP_NUMBER:
        return `mastersystem eq 'saperp' and masterid eq '${filterByValue}'`;
      case OrganisationSearchTableColumns.CRM_NETZE_ID:
        // DSV-3573: we do here a like-search because in CRM-Netze we have very often id like '0004321'
        return  `foreignKeys having (name eq 'msdynamicsn-accountnumber' and value like '*${filterByValue}')`;
      case OrganisationSearchTableColumns.MSDYNAMICS_ID:
        return `foreignKeys having (name eq 'msdynamics-accountnumber' and value eq '${filterByValue}')`;
    }
    return null;
  }
}
