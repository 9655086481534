import { Injectable } from "@angular/core";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable, throwError } from "rxjs";
import { Person as OpIamAdminPerson } from "../../generated/op-iam-admin/v1/model/person";
import { catchError } from "rxjs/operators";

@Injectable()
export class PersonApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/persons`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
    super(bfcNotificationService, bfcTranslationService);
  }

  searchPersonByEmail(email: string): Observable<OpIamAdminPerson[]> {
    const params = new HttpParams().set("filter", `(emailBusiness eq '${email}' or emailPrivate eq '${email}')`);
    return this.httpClient.get<OpIamAdminPerson[]>(this.baseUrl, { params }).pipe(
      super.catchHttpError(),
    );
  }

  getPersonById(personId: number): Observable<OpIamAdminPerson> {
    const url = `${this.baseUrl}/${personId}`;
    return this.httpClient.get<OpIamAdminPerson>(url).pipe(
      super.catchHttpError(),
    );
  }

  createPerson(opIamAdminPerson: OpIamAdminPerson): Observable<OpIamAdminPerson> {
    return this.httpClient.post<OpIamAdminPerson>(this.baseUrl, opIamAdminPerson).pipe(
      catchError((error: unknown) => {
        this.bfcNotificationService.showNotification({
          type: BfcNotificationType.ERROR,
          message: this.bfcTranslationService.translate("MANAGE_USER.CREATE.NOTIFICATION_PERSON_NOT_CREATED"),
        });
        return throwError(error);
      }),
    );
  }

  /**
   * activates a person in portal-services, when it doesn't have a commonPersonId yet.
   * Don't activate it, when it already has a ccid!!
   * for details see https://bkwiki.bkw-fmb.ch/display/AD/Merging+in+OP+IAM+nach+MHID-Migration
   * @param organisationId
   */
  createPortalServicesPerson(personId: number): Observable<OpIamAdminPerson> {
    const url = `${this.baseUrl}/${personId}/portalServicesCommonPersons`;

    return this.httpClient.post<OpIamAdminPerson>(url, {}).pipe(
      this.catchHttpError(),
    );
  }

  updatePerson(personId: number, payload: Partial<OpIamAdminPerson>): Observable<OpIamAdminPerson> {
    const url = `${this.baseUrl}/${personId}`;
    return this.httpClient.patch<OpIamAdminPerson>(url, payload).pipe(
      this.catchHttpError(),
    );
  }

  mergePerson(sourcePersonId: number, destinationCommonPersonId: number): Observable<void> {
    const url = `${this.baseUrl}/${sourcePersonId}/merge/${destinationCommonPersonId}`;
    // endpoint only brings httpStatus 204 back, no date in response body
    return this.httpClient.post<void>(url, {});
  }
}
