/**
 * OP IAM Admin Backend
 * OP IAM Admin Backend - used for Portal Administration (webapp and external backends)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MasterSystem = 'msdynamics' | 'msdynamicsn' | 'dbh' | 'oneportal' | 'sapisu' | 'sapisua' | 'saperp' | 'home-energy';

export const MasterSystem = {
    MSDYNAMICS: 'msdynamics' as MasterSystem,
    MSDYNAMICSN: 'msdynamicsn' as MasterSystem,
    DBH: 'dbh' as MasterSystem,
    ONEPORTAL: 'oneportal' as MasterSystem,
    SAPISU: 'sapisu' as MasterSystem,
    SAPISUA: 'sapisua' as MasterSystem,
    SAPERP: 'saperp' as MasterSystem,
    HOME_ENERGY: 'home-energy' as MasterSystem
};

