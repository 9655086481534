import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Service as OpIamAdminService } from "../../generated/op-iam-admin/v1/model/service";
import { Router } from "@angular/router";

@Injectable()
export class BundleGroupApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/bundlegroups`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService,
    private router: Router) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getAllBundleGroups(): Observable<OpIamAdminService[]> {
    return this.httpClient.get<OpIamAdminService[]>(this.baseUrl).pipe(
      super.catchHttpError(),
    );
  }

}
