import { createAction, props } from "@ngrx/store";
import {
  CustomCommonOrganisation as OpIamAdminCustomCommonOrganisation,
} from "../generated/op-iam-admin/v1/model/customCommonOrganisation";
import { Service as OpIamAdminService } from "../generated/op-iam-admin/v1/model/service";

// ---- LOADING ------------
export const loadApp = createAction(
  "[App] Load App",
);

export const loadOpContext = createAction(
  "[App] Load OpContext",
);

export const loadAllOrganisationsWithServices = createAction(
  "[App] Load all Organisation With Services Success",
);

export const loadBundleGroups = createAction(
  "[App] Load Bundle Groups",
);

// ---- UPDATING -----------
export const updateOpContext = createAction(
  "[App] OpContext updated Success",
  props<{ opContext: string }>(),
);

export const updateAllOrganisationsWithServices = createAction(
  "[App] Update all Organisation With Services Success",
  props<{ organisationsWithServices: OpIamAdminCustomCommonOrganisation[] }>(),
);

export const updateBundleGroups = createAction(
  "[App] Update bundle groups Success",
  props<{ bundleGroups: OpIamAdminService[] }>(),
);

// ---- RESTORING -----------
export const restoreAllAllOrganisationsWithServices = createAction(
  "[App] Restores previous all Organisations after loading form API failed",
);