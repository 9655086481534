<bfc-single-page-layout>
    <div class="content-container">
        <bfe-b2b-navigation *ngIf="enableB2bNavigation"
                            (routeRequest)="routeRequest($event)"
                            [navigationTree]="navigationTree"
                            [disableHelp]="true"
                            [disabledOrganisationSelection]="true"></bfe-b2b-navigation>
        <router-outlet></router-outlet>
    </div>
</bfc-single-page-layout>
