import { Service as OpIamAdminService } from "../../generated/op-iam-admin/v1/model/service";
export class GlobalServiceDisplayService {

  static getGlobalServiceDisplayName(service: OpIamAdminService): string {
    const defaultNone: string = "-";
    if (service) {
      return service.name && service.name.length > 0 ? service.name : service.code;
    }
    return defaultNone;
  }
}