import {
  CustomCommonOrganisation as OpIamAdminCustomCommonOrganisation,
} from "../generated/op-iam-admin/v1/model/customCommonOrganisation";
import { Service as OpIamAdminService } from "../generated/op-iam-admin/v1/model/service";

export interface AppState {
  opContext: string;
  organisationsWithServices: OpIamAdminCustomCommonOrganisation[];
  loadingOrganisationsWithServices: boolean;
  bundleGroups: OpIamAdminService[];
}

export const initialAppState: AppState = {
  opContext: undefined,
  organisationsWithServices: [],
  loadingOrganisationsWithServices: false,
  bundleGroups: [],
};