import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable, throwError } from "rxjs";
import { Account as OpIamAdminAccount } from "../../generated/op-iam-admin/v1/model/account";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Service as OpIamAdminService } from "../../generated/op-iam-admin/v1/model/service";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class AccountApiService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/accounts`;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService,
    private router: Router) {
    super(bfcNotificationService, bfcTranslationService);
  }

  getAccountByCommonPersonId(commonPersonId: number): Observable<OpIamAdminAccount> {
    const params = new HttpParams().set("commonPersonId", String(commonPersonId));
    return this.httpClient.get<OpIamAdminAccount>(this.baseUrl, { params }).pipe(
      super.catchHttpError(),
    );
  }

  getGlobalAccountServices(accountId: string): Observable<OpIamAdminService[]> {
    const url = `${this.baseUrl}/${accountId}/services`;
    return this.httpClient.get<OpIamAdminService[]>(url).pipe(
      super.catchHttpError(),
    );
  }

  getCalculatedAccountServices(accountId: string): Observable<OpIamAdminService[]> {
    const url = `${this.baseUrl}/${accountId}/calculatedServices`;
    return this.httpClient.get<OpIamAdminService[]>(url).pipe(
      super.catchHttpError(),
    );
  }

  createAccount(opIamAdminAccount: OpIamAdminAccount): Observable<OpIamAdminAccount> {
    const url = `${this.baseUrl}?setPasswordEmail=true`;
    return this.httpClient.post<OpIamAdminAccount>(url, opIamAdminAccount)
      .pipe(
        catchError((error: unknown) => {
          this.router.navigate(["/manage-user/search"], { queryParams: { email: opIamAdminAccount.loginEmail } });
          return throwError(error);
        }),
        this.catchHttpError("MANAGE_USER.CREATE.NOTIFICATION_ACCOUNT_NOT_CREATED"),
      );
  }

  updateAccountLanguage(accountId: string, language: string): Observable<OpIamAdminAccount> {
    const url = `${this.baseUrl}/${accountId}/language/${language}`;
    return this.httpClient.patch<OpIamAdminAccount>(url, null).pipe(
      this.catchHttpError(),
    );
  }

  updateAccountDefaultOrganisation(accountId: string, defaultOrganisation: number): Observable<OpIamAdminAccount> {
    const url = `${this.baseUrl}/${accountId}/defaultorganisation/${defaultOrganisation}`;
    return this.httpClient.patch<OpIamAdminAccount>(url, null).pipe(
      this.catchHttpError(),
    );
  }

  assignOrganisationServiceToAccount(accountId: string,
    commonOrganisationId: number,
    serviceId: number): Observable<void> {
    const url = `${this.baseUrl}/${accountId}/commonorganisations/${commonOrganisationId}/services/${serviceId}`;
    return this.httpClient.post<void>(url, null).pipe(
      this.catchHttpError(),
    );
  }

  removeOrganisationServiceFromAccount(accountId: string,
    commonOrganisationId: number,
    serviceId: number,
  ): Observable<void> {
    const url: string = `${this.baseUrl}/${accountId}/commonorganisations/${commonOrganisationId}/services/${serviceId}`;
    return this.httpClient.delete<void>(url).pipe(
      this.catchHttpError(),
    );
  }

  assignGlobalServiceToAccount(accountId: string, serviceId: number): Observable<void> {
    const url = `${this.baseUrl}/${accountId}/services/${serviceId}`;
    return this.httpClient.post<void>(url, null).pipe(
      super.catchHttpError(),
    );
  }

  removeGlobalServiceFromAccount(accountId: string, serviceId: number): Observable<void> {
    const url = `${this.baseUrl}/${accountId}/services/${serviceId}`;
    return this.httpClient.delete<void>(url).pipe(
      super.catchHttpError(),
    );
  }

  enableAccount(accountId: string, value: boolean) {
    if (value) {
      const url: string = `${this.baseUrl}/${accountId}/activate`;
      return this.httpClient.patch<void>(url, null).pipe(
        this.catchHttpError(),
      );
    } else {
      const url: string = `${this.baseUrl}/${accountId}/deactivate`;
      return this.httpClient.patch<void>(url, null).pipe(
        this.catchHttpError(),
      );
    }
  }

}
