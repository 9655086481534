import { OpAdminAppConfiguration } from "../../configuration";

export const COMMON_CONFIG_T: OpAdminAppConfiguration = {
  backendSettings: {
    iamAdminApiUrl: "https://api-iam-admin-t.bkw.ch",
    opEdmApiUrl: "https://api.oneportal-edm-t.bkw.ch/api",
  },
  documentsAdminUrl: "https://admin-documents-t.bkw.ch/",
  supportEmail: "jad_t@bkw.ch",
};
