import { IBfcConfiguration } from "@bfl/components/configuration";
import { GENERAL_BKW_ENV } from "./environments/bkw/general";
import { LOCAL_BKW_ENV } from "./environments/bkw/local";
import { T_BKW_ENV } from "./environments/bkw/t";
import { Q_BKW_ENV } from "./environments/bkw/q";
import { P_BKW_ENV } from "./environments/bkw/p";
import { LOCAL_ENV_HINNI } from "./environments/hinni/local";
import { T_ENV_HINNI } from "./environments/hinni/t";
import { Q_ENV_HINNI } from "./environments/hinni/q";
import { P_ENV_HINNI } from "./environments/hinni/p";
import { GENERAL_ENV_HINNI } from "./environments/hinni/general";

export const configuration: IBfcConfiguration[] = [
  {
    theme: "bkw",
    general: GENERAL_BKW_ENV,
    local: LOCAL_BKW_ENV,
    t: T_BKW_ENV,
    q: Q_BKW_ENV,
    p: P_BKW_ENV,
  },
  {
    theme: "hinni",
    general: GENERAL_ENV_HINNI,
    local: LOCAL_ENV_HINNI,
    t: T_ENV_HINNI,
    q: Q_ENV_HINNI,
    p: P_ENV_HINNI,
  },
];

export interface OpAdminAppConfiguration {
  backendSettings?: BackendSettings;
  documentsAdminUrl?: string;
  accountUrl?:  string;
  supportEmail?: string;
  maintenanceSettings?: MaintenanceSettings;
  globalNavSettings?: GlobalNavSettings;
  disableB2bNavigation?: boolean;
  allowEditMasterSystemOnUserCreation?: boolean;
  forceOtpRole?: string;
  isTemporaryRole?: string;
}

export interface BackendSettings {
  iamAdminApiUrl?: string;
  opEdmApiUrl?:  string;
  homeEnergyApiUrl?:  string;
}

export interface GlobalNavSettings {
  enableAccountMenu?: boolean;
  enableGlobalNavigationApi?: boolean;
  enableContactLink?: boolean;
  enablePromoSlots?: boolean;
}

export interface MaintenanceSettings {
  overviewDateFormat?: string;
  shownDateFormat?: string;
  shownTimeFormat?: string;
  storedDateFormat?: string;
}
