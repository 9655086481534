import { AppState, initialAppState } from "./app.state";
import { ActionReducerMap, createReducer, on } from "@ngrx/store";
import {
  updateAllOrganisationsWithServices,
  updateOpContext,
  loadAllOrganisationsWithServices,
  restoreAllAllOrganisationsWithServices, updateBundleGroups,
} from "./app.actions";

const loadingOrganisationsWithServicesReducer = createReducer(
  initialAppState.loadingOrganisationsWithServices,
  on(loadAllOrganisationsWithServices, () => true),
  on(updateAllOrganisationsWithServices, () => false),
  on(restoreAllAllOrganisationsWithServices, () => false),
);

const organisationsWithServicesReducer = createReducer(
  initialAppState.organisationsWithServices,
  on(updateAllOrganisationsWithServices,
    (state, { organisationsWithServices }) => (organisationsWithServices)),
);

const opContextReducer = createReducer(
  initialAppState.opContext,
  on(updateOpContext, (opContextState, { opContext }) => (opContext)),
);

const bundleGroupsReducer = createReducer(
  initialAppState.bundleGroups,
  on(updateBundleGroups, (state, { bundleGroups }) => (bundleGroups)),
);

export const reducers: ActionReducerMap<AppState> = {
  opContext: opContextReducer,
  organisationsWithServices: organisationsWithServicesReducer,
  loadingOrganisationsWithServices: loadingOrganisationsWithServicesReducer,
  bundleGroups: bundleGroupsReducer,
};