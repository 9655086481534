import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";
import { UserDataService } from "./user-data.service";
import { UserDataAccessService } from "./user-data-access.service";
import { UserData } from "./model/user-data";

@Injectable()
export class UserSearchService {
  private _searchEmail: BehaviorSubject<string> = new BehaviorSubject(undefined);

  private searchingCount: number = 0;

  users: UserData[] = [];

  searchError: Error = null; // be aware! if your behaviorSubject ends in an error,
  // your subscription is dead!

  constructor(private userDataAccessService: UserDataAccessService,
    private userDataService: UserDataService) {

    // appends the employer address in the employer field
    const expandEmployerAddresses = true;
    this.searchError = null;

    this._searchEmail.pipe(
      switchMap(email => {
        this.userDataService.setData(null);
        this.searchError = null;
        this.users = [];
        if (email) {
          this.searchingCount++;
          return this.userDataAccessService.searchUsersWithEmployer(email, false, expandEmployerAddresses)
            .pipe(
              finalize(() => this.searchingCount--),
            );
        } else {
          return of([]);
        }
      }),
    ).subscribe((users: UserData[]) => {
      this.searchError = null;
      this.users = users
        .sort( (u1: UserData, u2: UserData) =>
          u1.masterSystem?.localeCompare(u2.masterSystem)); // DSV-601: MsDynamics first
      this.userDataService.setData(this.users.length === 1 ? this.users[0] : null);
    }, (error: unknown) => {
      // be aware! after an error is throwed here, the subscription is dead!
      this.users = null;
      this.userDataService.setData(null);
      this.searchError = error as Error;
    });
  }

  get email(): string {
    return this._searchEmail.value;
  }

  set email(value: string) {
    if (this._searchEmail.value !== value) {
      this._searchEmail.next(value);
    }
  }

  get searching(): boolean {
    return this.searchingCount > 0;
  }
}
