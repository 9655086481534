import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import jwt_decode from "jwt-decode";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { AdminPermissionApiService } from "./op-iam-admin/admin-permission-api.service";
import { AdminPermissions as OpIamAdminPermissions } from "../generated/op-iam-admin/v1/model/adminPermissions";
import { Service as OpIamAdminService } from "../generated/op-iam-admin/v1/model/service";
import { ServiceTypeEnum } from "../generated/op-iam-admin/v1/model/serviceTypeEnum";

@Injectable()
export class AdminPermissionService {
  private adminPermissions: OpIamAdminPermissions;

  constructor(private adminPermissionApiService: AdminPermissionApiService,
    private bfcAuthService: BfcAuthenticationService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  initialize(): Promise<OpIamAdminPermissions> {
    return this.adminPermissionApiService.getMyAdminPermissions().pipe(
      tap((adminPermissions: OpIamAdminPermissions) => {
        this.adminPermissions = adminPermissions;
      })).toPromise();
  }

  get isKonzernOrBrandOrServiceAdmin(): boolean {
    const permissions = this.adminPermissions;
    return !!permissions &&
            (
              permissions.konzernAdmin ||
                permissions.brandAdmin ||
                permissions.serviceAdminPermissions?.length > 0
            );
  }

  get canCreateUsers(): boolean {
    return this.isAnyAdmin;
  }

  get canEnableUsers(): boolean {
    // only a KonzernAdmin can!
    return this.adminPermissions?.konzernAdmin;
  }

  get canDeleteUsers(): boolean {
    // only a KonzernAdmin can!
    return this.adminPermissions?.konzernAdmin;
  }

  get canAdministrateGlobalServices(): boolean {
    return this.isKonzernOrBrandOrServiceAdmin;
  }

  filterServicesByAdminTypeAndGlobalServiceType(services: OpIamAdminService[]): OpIamAdminService[] {
    if (this.isKonzernOrBrandAdmin) {
      return services.filter( (service) => service.serviceType === ServiceTypeEnum.GLOBAL);
    } else if (this.canAdministrateGlobalServices) {
      // service admin
      return services.filter( (service) => service.serviceType === ServiceTypeEnum.GLOBAL && 
        this.adminPermissions.serviceAdminPermissions?.indexOf(service.code) >= 0,
      ); 
    }
    return [];
  }

  get isAnyAdmin(): boolean {
    const permissions = this.adminPermissions;
    return !!permissions &&
            (
              permissions.konzernAdmin ||
                permissions.brandAdmin ||
                permissions.serviceAdminPermissions?.length > 0 ||
                permissions.superUserPermissions?.length > 0
            );
  }

  get isKonzernOrBrandAdmin(): boolean {
    const permissions = this.adminPermissions;
    return !!permissions &&
            (
              permissions.konzernAdmin ||
                permissions.brandAdmin
            );
  }

  getOpContext(): string {
    const opContextPropertyName = "op_context";
    const tokenDecoded = jwt_decode(this.bfcAuthService.token);
    return tokenDecoded && tokenDecoded[opContextPropertyName]
      ? tokenDecoded[opContextPropertyName] : this.getLocalContext();
  }

  private getLocalContext(): string {
    if (this.bfcConfigurationService.environment.stage === "local") {
      return "bkw"; // default context for local.
    }
    return null;
  }
}
