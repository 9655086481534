import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AdminPermissionService } from "../core/admin-permission.service";
import {
  loadAllOrganisationsWithServices, loadApp, loadBundleGroups,
  loadOpContext, restoreAllAllOrganisationsWithServices,
  updateAllOrganisationsWithServices, updateBundleGroups,
  updateOpContext,
} from "./app.actions";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomCommonOrganisationApiService } from "../core/op-iam-admin/custom-common-organisation-api.service";
import { BundleGroupApiService } from "../core/op-iam-admin/bundle-group-api.service";

@Injectable()
export class AppEffects {
  constructor(
    private actions: Actions,
    private adminPermissionService: AdminPermissionService,
    private customCommonOrgService: CustomCommonOrganisationApiService,
    private bundleGroupApiService: BundleGroupApiService,
  ) {
  }

  loadAppEffect = createEffect(() => {
    return this.actions.pipe(
      ofType(loadApp),
      mergeMap(() => [
        loadOpContext(),
        loadAllOrganisationsWithServices(),
        loadBundleGroups(),
      ]),
    );
  });

  loadAllOrganisationsWithServicesEffect = createEffect( () => {
    return this.actions.pipe(
      ofType(loadAllOrganisationsWithServices),
      filter(() => this.adminPermissionService.isAnyAdmin),
      switchMap(() => {
        return this.customCommonOrgService.getAllCustomCommonOrganisationsWithAnyService()
          .pipe(
            map(organisationsWithServices => {
              organisationsWithServices = organisationsWithServices
                .filter(organisation => organisation?.services?.length > 0);
              return updateAllOrganisationsWithServices({ organisationsWithServices });
            }),
            catchError(() => {
              return of(restoreAllAllOrganisationsWithServices());
            }),
          );
      }),
    );
  });

  loadOpContextEffect = createEffect( () => {
    return this.actions.pipe(
      ofType(loadOpContext),
      mergeMap(() => of(this.adminPermissionService.getOpContext())
        .pipe(
          map((opContext: string) => {
            return updateOpContext({ opContext: opContext });
          }),
          catchError(() => of(null)),
        ),
      ));
  });

  loadBundleGroups = createEffect( () => {
    return this.actions.pipe(
      ofType(loadBundleGroups),
      filter(() => this.adminPermissionService.isAnyAdmin),
      switchMap(() => this.bundleGroupApiService.getAllBundleGroups()
        .pipe(
          map(bundleGroups => {
            return updateBundleGroups({ bundleGroups });
          }),
        ),
      ));
  });
}