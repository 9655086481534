import { BehaviorSubject, Observable, Subject } from "rxjs";

export class ResettableDataStore<T> {
  private _data$: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  private _reset$: Subject<T> = new Subject<T>();

  private _resetCopy: T = null;

  getData$(): Observable<T> {
    return this._data$.asObservable();
  }

  getData(): T {
    return this._data$.value;
  }

  setData(value: T) {
    if (value) {
      this._resetCopy = JSON.parse(JSON.stringify(value));
      this._data$.next(JSON.parse(JSON.stringify(value)));
    } else {
      this._resetCopy = null;
      this._data$.next(null);
    }
  }

  replaceData(updateValue: T) {
    // Object.assign(data, updateValue); does not work if the key is not present in the "updateValue".
    // Grab all keys from both objects and reset them to value from "updateValue".
    const data = this.getData();

    const keysData = Object.keys(data);
    const keysUpdateValue = Object.keys(updateValue);
    const uniqueKeys = [...new Set([...keysData, ...keysUpdateValue])];

    uniqueKeys.forEach(function (key: string) {
      data[key] = updateValue[key];
    });
  }

  reset(): void {
    this.replaceData(JSON.parse(JSON.stringify(this._resetCopy)));
    this._reset$.next(this.getData());
  }

  getReset$(): Observable<T> {
    return this._reset$.asObservable();
  }

  getUntouchedData() : Readonly<T> {
    return this._resetCopy;
  }
}
