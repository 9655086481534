import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class MaintenanceDateHelperService {

  constructor(private bfcConfigurationService: BfcConfigurationService) {
  }

  /**
   * Extract the date and time from a date and a time control. If the time control does not contain any values, only
   * the date control will be taken in account. If both controls are empty, an invalid Moment will be returned.
   * @param startDateControl
   * @param startTimeControl
   */
  getStartDateTimeAsMoment(startDateControl: AbstractControl, startTimeControl: AbstractControl): Moment {
    const startDateTimeMoment = moment(startDateControl?.value);
    const startTimeMoment =  this.getTimeByShownTimeFormat(startTimeControl?.value);
    return startDateTimeMoment.set({
      "hour": startTimeMoment.get("hour"),
      "minute": startTimeMoment.get("minute"),
      "second": 0,
    });
  }

  /**
   * Extract the date and time from a date and a time control. If the time control does not contain any values, only
   * the date control will be taken in account. If both controls are empty, NaN will be returned.
   * @param startDateControl
   * @param startTimeControl
   */
  getStartDateTimeAsUnix(startDateControl: AbstractControl, startTimeControl: AbstractControl): number {
    return this.getStartDateTimeAsMoment(startDateControl, startTimeControl).unix();
  }

  /**
   * Extract the date and time from a date and a time control. If the time control does not contain any values, only
   * the date control will be taken in account. If both controls are empty, null will be returned.
   * @param endDateControl the control holding the date value
   * @param endTimeControl the control holding the time value
   */
  getEndDateTimeAsMoment(endDateControl: AbstractControl, endTimeControl: AbstractControl): Moment {
    const endDateFormValue = endDateControl?.value;
    let endDateTime: Moment = null;

    if (endDateFormValue) {
      const endDateMoment = moment(endDateFormValue);
      const endTimeFormValue = endTimeControl?.value;

      if (!endTimeFormValue) {
        return endDateMoment;
      }

      const endTimeMoment = this.getTimeByShownTimeFormat(endTimeControl?.value);
      endDateTime = endDateMoment.set({
        "hour": endTimeMoment.get("hour"),
        "minute": endTimeMoment.get("minute"),
        "second": 0,
      });
    }

    return endDateTime;
  }

  /**
   * Extract the date and time from a date and a time control. If the time control does not contain any values, only
   * the date control will be taken in account. If both controls are empty, undefined will be returned.
   * @param endDateControl
   * @param endTimeControl
   */
  getEndDateTimeAsUnix(endDateControl: AbstractControl, endTimeControl: AbstractControl): number {
    return this.getEndDateTimeAsMoment(endDateControl, endTimeControl)?.unix();
  }

  getDateFormattedWithStoredDateFormat(date: Moment): string {
    return date?.format(this.bfcConfigurationService.configuration.maintenanceSettings.storedDateFormat);
  }

  getDateByStoredDateFormat(dateString: string): Moment {
    return moment(dateString,
      this.bfcConfigurationService.configuration.maintenanceSettings.storedDateFormat);
  }

  getTimeFormattedWithShownTimeFormat(date: Moment): string {
    return date?.format(this.bfcConfigurationService.configuration.maintenanceSettings.shownTimeFormat);
  }

  getTimeByShownTimeFormat(timeString: string): Moment {
    return moment(timeString,
      this.bfcConfigurationService.configuration.maintenanceSettings.shownTimeFormat);
  }

  getDateFormattedWithShownDateFormat(date: Moment): string {
    return date?.format(this.bfcConfigurationService.configuration.maintenanceSettings.shownDateFormat);
  }

  getDateFormattedWithOverviewDateFormatOrDefault(date: Moment, defaultValue: string): string {
    return date?.isValid() ?
      date.format(this.bfcConfigurationService.configuration.maintenanceSettings.overviewDateFormat) :
      defaultValue;
  }

  /**
   * Validates that the start date and time is before the end date and time. Returns an invalidEndDateTime
   * ValidationError is not so.
   * @param startDateControl
   * @param startTimeControl
   * @param endDateControl
   * @param endTimeControl
   */
  validateStartDateIsBeforeEndDate(startDateControl: string, startTimeControl: string,
    endDateControl: string, endTimeControl: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const validationErrors: ValidationErrors = {};

      if (!group.get(startDateControl)
        || !group.get(startTimeControl)
        || !group.get(endDateControl)
        || !group.get(endTimeControl)) {
        return validationErrors;
      }

      const startDateTime = this.getStartDateTimeAsUnix(group.get(startDateControl), group.get(startTimeControl));
      const endDateTime = this.getEndDateTimeAsUnix(group.get(endDateControl), group.get(endTimeControl));

      if (endDateTime != null && startDateTime >= endDateTime) {
        validationErrors.invalidEndDateTime = true;
      }

      return validationErrors;
    };
  }
}