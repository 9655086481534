export const DE_TRANSLATIONS = {
  SERVICE_NAME: "Admin",
  REQUIRED: "Pflichtfeld",
  MAX_LENGTH: "max. {{maxlength}} Zeichen",
  MIN_LENGTH: "mind. {{minlength}} Zeichen",
  ONLY_NUMBERS_ALLOWED: "Nur Zahlen erlaubt",
  MANAGE_USER: {
    TITLE: "Benutzerverwaltung",
    SEARCH: {
      TITLE: "Benutzer & Berechtigungen",
      SEARCH_LABEL: "Benutzer suchen:",
      EMAIL: "E-Mail Adresse eingeben",
      LOGIN_EMAIL: "Login E-Mail:",
      ACTIVE: "aktiv",
      USER_IS_ACTIVE_IN_CUSTOMER_ADVISOR: "ACHTUNG: Benutzer befindet sich aktuell in einer aktiven Kundensicht!",
      INACTIVE: "inaktiv",
      NO_RESULTS: "Keine Ergebnisse",
      FAILED: "Suche nach Benutzer fehlgeschlagen",
      // für local, T & Q verwenden wir die Stellenpostfächer von jad_t@bkw.ch resp jad_q@bkw.ch (siehe configs)
      // für PROD verwenden wir Service Now, um ein Ticket zu erstellen via emailadresse: serviceDesk@bkw.ch
      ACCOUNT_WHITELABEL_INFO: "Dieser Account existiert entweder bereits in einem anderen Brand, ist mit keiner gültigen Person verknüpft oder ist fehlerhaft. ACHTUNG: falls dieser Account für den aktuellen Brand {{context}} verwendet werden soll, bitte eine E-Mail mit Betreff \"{{betreff_support_email}}\" an {{support_email}} mit dem gewünschten Account und dem für den Account gewünschten Brand senden! Danke.",
      SUPPORT_BETREFF: "[für CIT_JAVA]: Personen context-übergreifend mergen",
      CREATE_USER: "Benutzer anlegen",
      USER_DATA_TAB_LABEL: "Benutzerdaten",
      SERVICES_TAB_LABEL: "Berechtigungen Services",
      GLOBAL_SERVICES_TAB_LABEL: "Globale Berechtigungen",
      ADMIN_PERMISSIONS_TAB_LABEL: "Berechtigungen Portal Admin",
      BELVIS_ACTIVATION_LABEL: "Belvis Aktivierung",
      DELETE_USER_TAB_LABEL: "Benutzer löschen",
      KEYCLOAK_ACTIONS_TAB_LABEL: "Aktionen auslösen",
      PERSON_ONLY: "(kein Account vorhanden)",
      FIRSTNAME: "Vorname",
      LASTNAME: "Nachname",
      EMPLOYER: "Arbeitgeber",
      MASTER_SYSTEM: "MasterSystem",
    },
    DETAILS: {
      EMAIL: "E-Mail Adresse",
      MASTERSYSTEM_EMAIL: "Korrespondenz E-Mail im MasterSystem",
      SALUTATION: "Anrede",
      PHONE_INVALID: "Telefonnummer ungültig",
      FIRST_NAME: "Vorname",
      LAST_NAME: "Nachname",
      LANGUAGE: "Sprache",
      PHONE_NUMBER: "Telefonnummer",
      SAP_ISU_ACCOUNT_ID: "GP Nummer",
      MASTERSYSTEM: "MasterSystem",
      DEFAULT_ORGANISATION: "Standardorganisation",
      EMPLOYER: "Arbeitgeber",
      EMPLOYER_PLACEHOLDER: "Organisationsname eingeben",
      CONFIRM: "Ich bestätige, dass alle Änderungen korrekt sind",
      CREATE_ACCOUNT: "Account erstellen",
      SAVE: "Speichern",
      CANCEL: "Abbrechen",
      REJECT: "Verwerfen",
      SUCCESS_NOTIFICATION: "Benutzerdaten erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Benutzerdaten konnten nicht gespeichert werden",
      ERROR_RESTRICTED_ROLE: "Der Benutzer ist bereits auf einer anderen Organisation für {{role}} berechtigt. Bitte wenden Sie sich an b2b-registrierung@bkw.ch.",
      DISCARD_SUCCESS_NOTIFICATION: "Benutzerdaten erfolgreich verworfen",
      DISCARD_ERROR_NOTIFICATION: "Benutzerdaten konnten nicht verworfen werden",
      INVALID_EMAIL: "Ungültige E-Mail Adresse",
      MERGE_BUTTON: "Zusammenführen",
      MERGE_DESC: "Persone(n) mit gleicher E-Mail in MS Dynamics CRM gefunden.",
      EMPLOYER_NOT_FOUND: {
        TEXT: "Organisation nicht gefunden?",
        LINK_TEXT: "Bitte Organisation hier erfassen",
      },
      EMPLOYER_UNAUTHORIZED: "Anderer Arbeitgeber",
      MERGE_DIALOG: {
        DIALOG_TITLE: "Personen zusammenführen",
        NAME: "Name",
        MASTERSYSTEM: "MasterSystem",
        BKWCUSTOMERNUMBER: "CRM ID: ",
        BKWCUSTOMERNUMBER_HINT: "bkwcustomernumber",
        EMPLOYER_HINT: "Arbeitgeber",
        LOGINEMAIL: "Login Email",
        SOURCE_PERSON: "Bestehende Person",
        TARGET_PERSON: "Zielpersonen in MS Dynamics CRM mit",
        EMAIL: "Korrespondenz E-Mail",
        BUTTON_CANCEL: "Abbrechen",
        BUTTON_MERGE: "Unwiderruflich zusammenführen",
        IAMSURE: "Ja, ich will die Datensätze zusammenführen und bin mir bewusst, dass dies nicht rückgängig gemacht werden kann.",
        NOTIFICATION_SUCCESS: "Personen erfolgreich zusammengeführt. Bitte laden Sie die Seite neu.",
        NOTIFICATION_ERROR: "Personen konnten NICHT zusammengeführt werden. Bitte laden Sie die Seite neu und wiederholen Sie den Vorgang. Code: {{errorCode}}",
        NOTIFICATION_ERROR_409: "Personen konnten NICHT zusammengeführt werden. Zielperson mit anderem Account verknüpft. Code: {{errorCode}}",
        RELOAD: "Seite neu laden",
      },
      GLOBAL_SERVICES: "Globale Berechtigungen",
      GLOBAL_SERVICES_WARNING: "Die globalen Berechtigungen des Accounts konnten noch nicht geladen werden. Versuchen Sie es später nochmals",
      NO_GLOBAL_SERVICES: "keine globalen Berechtigungen verfügbar",
    },
    FORCE_MIGRATION: {
      MESSAGE: "Aktion war nicht erfolgreich. Der Benutzer ist bereits in SAP IS-U / my.bkw.ch vorhanden.",
      FORCE_MIGRATE: "Migration forcieren",
      CLOSE: "Schliessen",
    },
    SAVE_DETAILS_DIALOG: {
      TITLE: "Änderungen speichern?",
      TITLE_CREATE_ACCOUNT: "Account erstellen?",
      CREATE_ACCOUNT_NOTICE: "Beim Bestätigen wird für den Benutzer ein Account erstellt und automatisch eine E-Mail für die Accountaktivierung ausgelöst.",
      REJECT: "Verwerfen",
      CANCEL: "Abbrechen",
      CONFIRM: "Jetzt bestätigen",
    },
    SAVE_ERRORS_DIALOG: {
      TITLE: "Berechtigung bereits vergeben",
      CONTENT: "Der Benutzer ist bereits auf einer anderen Organisation für APP {{permissions}} berechtigt. Bitte wenden Sie sich an b2b-registrierung@bkw.ch.",
      CLOSE: "Jetzt schliessen",
    },
    SAVE_PERMISSONS_DIALOG: {
      TITLE: "Änderungen speichern?",
      REJECT: "Verwerfen",
      CONFIRM: "Jetzt bestätigen",
    },
    DETAILS_SUMMARY: {
      TITLE: "Benutzerdaten",
      TELEPHONE: "Tel. {{phoneNumber}}",
      LANGUAGE: "Sprache: {{language}}",
    },
    PERMISSIONS_SUMMARY: {
      NO_PERMISSIONS: "Keine Berechtigungen",
      SERVICES_TO_ADD: "Hinzugefügte Berechtigungen:",
      SERVICES_TO_REMOVE: "Entfernte Berechtigungen:",
    },
    PERMISSIONS: {
      SEARCH_ORGANISATION: "Organisation suchen",
      ONLY_ASSIGNED_ORGANISATIONS: "Nur zugewiesene Organisationen anzeigen",
      LIST_LABEL: "Organisation & Berechtigung auswählen",
      CONFIRM: "Ich bestätige, dass alle Änderungen korrekt sind",
      SAVE: "Speichern",
      SUCCESS_NOTIFICATION: "Berechtigungen erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Berechtigungen konnten nicht gespeichert werden",
      ERROR_COMPANY_MERGE: "Fehler Firma: Die von Ihnen gewählte Firma ist nicht im MS Dynamics vorhanden. Bitte schicken Sie eine E-Mail mit der GP Nummer an 144d16b5.bkwfmbenergie.onmicrosoft.com@emea.teams.ms, um die Firma mit GP Nummer im CRM eintragen zu lassen und versuchen sie es erneut.",
      ERROR_PERSON_MERGE: "Fehler Person: Die von Ihnen gewählte Person ist nicht im MS Dynamics angelegt. Bitte führen Sie die Datensätze zusammen (Tab “Benutzerdaten“) oder melden Sie sich beim Service Desk mit dem Betreff “Admin Tool fehlende Person in MSD” für die Gruppe “CIT_JAVA” und einem Screenshot des Admin Tools.",
      HINT_ERROR_COMPANY_MERGE: "Fehler Firma",
      HINT_ERROR_PERSON_MERGE: "Fehler Person",
      HINT_FORCES_2FA: "Diese Berechtigung führt zu einer 2FA-Pflicht.",
    },
    ADMIN_PERMISSIONS_SUMMARY: {
      NO_PERMISSIONS: "Keine Berechtigungen",
    },
    ADMIN_PERMISSIONS: {
      TITLE: "Rolle hinzufügen/ändern",
      MODE: "Rolle auswählen",
      SUMMARY_SERVICE_ADMIN: "Service Admin Berechtigungen",
      SUMMARY_SUPERUSER: "Superuser Berechtigungen",
      SUMMARY_CUSTOMER_ADVISOR: "Kundenberater Berechtigungen",
      SERVICE_ADMIN: "Service Admin",
      SUPERUSER: "Superuser",
      CUSTOMER_ADVISOR: "Kundenberater",
      CHOOSE_GLOBAL_SERVICES: "Globale Service(s) auswählen",
      CHOOSE_SERVICES: "Service(s) auswählen",
      CHOOSE_CUSTOMER_ADVISOR_SERVICES: "Kundenberater Service(s) auswählen",
      SAVE: "Speichern",
      ABORT: "Abbrechen",
      EDIT_PERMISSIONS: "Rolle hinzufügen/ändern",
      SUCCESS_NOTIFICATION: "Berechtigungen erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Berechtigungen konnten nicht gespeichert werden",
    },
    DELETE: {
      REASON: "Grund der Löschung",
      REASON_HINT: "z.B. Mitarbeiter verlässt die Unternehmung",
      WARNING: "Hinweis: Der Benutzer wird unwiderruflich in onePortal gelöscht! Dies gilt nur für onePortal und nicht für andere Systeme. Alle damit verbundenen Einstellungen werden gelöscht.",
      CONFIRM: "Jetzt bestätigen",
      SUCCESS_NOTIFICATION: "Benutzer erfolgreich gelöscht",
      ERROR_NOTIFICATION: "Benutzer konnten nicht gelöscht werden",
    },
    ACTION: {
      CONFIRM: "Speichern & Aktion auslösen",
      TRIGGER_ACTIONS: "Aktionen auslösen",
      TWO_FACTOR_AUTH: "Zwei-Faktor-Authentifizierung (2FA)",
      TWO_FACTOR_CONFIGURED: "Zwei-Faktor-Authentifizierung vom Benutzer aktiviert:",
      TWO_FACTOR_FORCED: "Zwei-Faktor-Authentifizierung aufgrund Berechtigungen erzwungen:",
      DEACTIVATE_TWO_FACTOR_DESCRIPTION: "Die Zwei-Faktor-Authentifizierung des Benutzers wird deaktiviert. Er loggt sich ab sofort nur noch mit E-Mail und Passwort ein. Der Benutzer sollte die Konfiguration auf seiner Authentifikatoren-App löschen.",
      DEACTIVATE_TWO_FACTOR: "Zwei-Faktor-Authentifizierung entfernen",
      RESET_OTP: "Zwei-Faktor-Authentifizierung (zurück-) setzen",
      RESET_OTP_DESCRIPTION: "Der Benutzer erhält eine E-Mail mit der Aufforderung, die 2FA einzurichten, auch wenn er es bereits eingerichtet hatte.",
      REJECT: "Verwerfen",
      USER_ACTIVE: "Benutzer aktiv",
      ACTIVATE_DEACTIVATE_USER: "Benutzer de-/aktivieren",
      ACTIVATE_DEACTIVATE_USER_TEXT: "Deaktivierte Benutzer können sich nicht mehr einloggen. Bei Fragen: b2b-registrierung@bkw.ch",
      ACTIVATE_USER: "Benutzer aktivieren",
      DEACTIVATE_USER: "Benutzer deaktivieren",
      LOGIN_DATA: "Logindaten",
      EMAIL_VERIFIED: "E-Mail Adresse ist verifiziert: ",
      EMAIL_VERIFY: "E-Mail Adresse verifizieren",
      EMAIL_VERIFY_DESCRIPTION: "Der Benutzer erhält eine E-Mail mit der Aufforderung, seine E-Mail Adresse zu verifizieren und damit seinen Account zu aktivieren.",
      RESET_PASSWORD: "Passwort zurücksetzen",
      RESET_PASSWORD_DESCRIPTION: "Der Benutzer erhält eine E-Mail mit der Aufforderung, sein Passwort neu zu setzen.",
      SUCCESS_NOTIFICATION: "Aktion wurde erfolgreich durchgeführt.",
      ERROR_NOTIFICATION: "Aktion konnte nicht durchgeführt werden.",
    },
    CREATE: {
      TITLE: "Neuer Benutzer",
      BACK_TO_OVERVIEW: "« zurück zur Übersicht",
      STEP_USER_DATA: {
        TITLE: "Benutzerdaten",
        CONTINUE: "Weiter",
        ABORT: "Abbrechen",
      },
      STEP_SERVICE_PERMISSIONS: {
        TITLE: "Ber. Services",
        BACK: "Zurück",
        CONTINUE: "Weiter",
      },
      STEP_ADMIN_PERMISSIONS: {
        TITLE: "Ber. Portal Admin",
        BACK: "Zurück",
        CONTINUE: "Weiter",
      },
      STEP_SUMMARY: {
        TITLE: "Bestätigung",
        USER_DETAILS_SUMMARY_TITLE: "Benutzerdaten",
        USER_PERMISSIONS_SUMMARY_TITLE: "Berechtigungen",
        BACK: "Zurück",
        CONFIRM: "Jetzt bestätigen",
      },
      SUCCESS_NOTIFICATION: "Benutzer erfolgreich erstellt",
      SUCCESS_NOTIFICATION_EMPLOYER_NOT_SET: "Benutzer erfolgreich erstellt aber Arbeitgeber konnte nicht gesetzt werden.",
      NOTIFICATION_PERSON_NOT_CREATED: "Das Erstellen der Personendaten im CRM-System dauerte länger als erwartet. Die Seite wird für Sie neu geladen, danach sollten die Personendaten sichtbar sein und Sie können mit der Account-Erstellung fortfahren. Falls nicht, prüfen Sie die Daten im CRM. Besten Dank für Ihr Verständnis.",
      NOTIFICATION_ACCOUNT_NOT_CREATED: "Der Account konnte aus technischen Gründen nicht erstellt werden. Versuchen Sie es später nochmals oder wenden Sie sich an den Administrator. Besten Dank.",
    },
    BELVIS_ACTIVATION: {
      ACTIVATION_TITLE: "Belvis Aktivierung",
      FOR: "Für",
      USER_HAS_VALID_CREDENTIALS_ALREADY: "wurden bereits gültige Belvis Credentials hinterlegt.",
      USER_HAS_INVALID_CREDENTIALS: "wurden Belvis Credentials hinterlegt; diese sind aber ungültig!",
      USER_NOT_BELVIS_USER: "Der User '{{value}}' ist kein Belvis User!",
      PASSWORD: "Passwort",
      USERNAME: "Benutzername",
      SUBMIT: "Aktivieren",
      SUCCESS_NOTIFICATION: "Die Belvis-Aktivierung wurde erfolgreich durchgeführt.",
      ERROR_SAVING_CREDENTIALS: "Die Credentials konnten nicht gespeichert werden.",
      ERROR_USER_ID_CONFLICT: "Dieser Belvis Benutzername ist bereits einem anderen OP-User zugeordnet.",
      ERROR_LOGIN_FAILED: "Ein Belvis-Login mit den eingegebenen Credentials war nicht möglich. Bitte Eingabe überprüfen.",
    },
    DELETE_CONFIRMATION_DIALOG: {
      TITLE: "Möchten Sie den Benutzer wirklich löschen?",
      ABORT: "Abbrechen",
      CONFIRM: "Jetzt bestätigen",
    },
    ACTIONS_CONFIRMATION_DIALOG: {
      TITLE: "Folgende Aktion wirklich auslösen?",
      TRIGGER_ACTIONS: "Folgende Aktionen auslösen:",
      TWO_FACTOR_DEACTIVATE: "2-Faktor-Authentifizierung deaktivieren",
      ABORT: "Verwerfen",
      CONFIRM: "Aktion auslösen",
      USER_EMAIL: "Benutzer E-Mail",
    },
    SERVICE_ADDED_INFO: {
      BELVIS: "M2C EDM Visualisieren: Sobald die Berechtigung in BelVis Web erteilt wurde, erhalten Sie eine Meldung von uns.",
      FORCES_2FA: "Der Benutzer wird aufgrund seiner Berechtigungen zur Nutzung von 2FA gezwungen.",
    },
    BACK_TO_OVERVIEW: "« zurück zur Übersicht",
  },
  MANAGE_ORGANISATION: {
    TITLE: "Organisationen verwalten",
    SECTORS: {
      A: "Landwirtschaft",
      B: "Bergbau",
      C: "Verarbeitendes Gewerbe",
      D: "Energieversorgung",
      E: "Wasserversorgung",
      F: "Baugewerbe",
      G: "Handel/Instandhaltung Motorfahrzeuge",
      H: "Verkehr",
      I: "Gastgewerbe",
      J: "Information/Kommunikation",
      K: "Finanzdienstleistungen",
      L: "Grundstückswesen",
      M: "Wirtschaftliche Dienstleistungen technisch",
      N: "Wirtschaftliche Dienstleistungen sonstige",
      O: "Öffentliche Verwaltung",
      P: "Erziehung/Unterricht",
      Q: "Gesundheitswesen",
      R: "Kunst/Unterhaltung",
      S: "Dienstleistungen sonstige",
      T: "Private Haushalte",
      U: "Extraterritoriale Organisationen",
      Z: "Haushalte",
    },
    SEARCH: {
      TITLE: "Organisationsverwaltung",
      SEARCH_HINT: "Suchen Sie nach Organisationsnamen, CRM ID, GP-Nummer oder EIC X Code:",
      SEARCH_LABEL: "Organisation suchen:",
      CATEGORY: "Suchkategorie",
      SEARCH_PLACEHOLDER: "Stichwort oder Nummer eingeben",
      NO_RESULTS: "Keine Ergebnisse",
      CREATE_ORGANISATION: "Organisation anlegen",
      OPTION: {
        VALUES: {
          name: "Name",
          masterhubId: "Masterhub ID",
          "portal-services-commonid": "Common Org ID",
          eicxCode: "EIC-X Code",
          sapisuGpNumber: "GP Nummer (sapisu)",
          saperpGpNumber: "GP Nummer (saperp)",
          crmNetzeId: "CRM Netze ID",
          msdynamicsId: "MS Dynamics ID",
        },
      },
      EXACT_SEARCH_TIP: "Exakte Suche in Anführungszeichen (z.B. \"Elektra\")",
      LABELS: {
        NAME: "Organisationsname",
        ADDRESS: "Adresse",
        MASTERHUB_ID: "CommonID (MH)",
        MSD_ID: "MsDynamics ID",
        CRM_NETZE_ID: "CRM Netze ID",
        MASTERSYSTEM: "MasterSystem",
        SAP_ISU_GP_NUMBER: "GP-Nummer (sapisu)",
        SAP_ERP_GP_NUMBER: "GP-Nummer (saperp)",
        EICX_CODE: "EIC X Code",
        HAS_SERVICES: "Hat Services",
      },
    },
    EDIT: {
      DETAILS_TAB_LABEL: "Organisationsdaten",
      PERMISSIONS_TAB_LABEL: "Berechtigungen",
      ASSIGNED_USERS_TAB_LABEL: "Zugewiesene Benutzer",
    },
    DETAILS: {
      TITLE: "Organisationsdaten",
      NAME: "Organisationsname",
      STREET: "Strasse",
      HOUSE_NO: "Hausnummer",
      ZIP_CODE: "PLZ",
      CITY: "Ort",
      COUNTRY: "Land",
      SECTOR: "Branche",
      PERMISSIONS_TITLE: "Berechtigungen",
      ACTIVE: {
        LABEL: "Status der Organisation",
        VALUES: {
          true: "Organisation mit Berechtigungen",
          false: "Organisation ohne Berechtigungen",
        },
        VALUES_SHORT: {
          true: "Mit Berechtigungen",
          false: "Ohne Berechtigungen",
        },
        HINT: "Hinweis: Beim Deaktivieren der Organisation verlieren alle zugewiesenen Benutzer ebenfalls die Berechtigungen dieser Organisation!",
      },
      CONTACT_DATA_TITLE: "Kontaktdaten (optional)",
      HOMEPAGE: "Homepage",
      INVALID_HOMEPAGE: "Ungültiger Weblink",
      EMAIL: "E-Mail Adresse",
      INVALID_EMAIL: "Ungültige E-Mail Adresse",
      PHONE_NUMBER: "Telefonnummer",
      INVALID_PHONE: "Ungültige Telefonnummer",
      ADDITIONAL_DATA_TITLE: "Zusätzliche Angaben (optional)",
      MASTERSYSTEM: "MasterSystem",
      EICX_CODE: "EIC X-Code via SAP",
      MSDYNAMICS_ID: "MS Dynamics ID",
      CRM_NETZE_ID: "CRM Netze ID",
      SAP_ISU_GP_NUMBER: "GP-Nummer (sapisu)",
      SAP_ERP_GP_NUMBER: "GP-Nummer (saperp)",
      CRM_NETZE_GP_NUMBER: "GP-Number CRM Netze",
      COMMERCIAL_REGISTER_ID: "Handelsregister ID",
      SEGMENT: {
        LABEL: "Organisationstyp",
        VALUES: {
          Undefined: "Unbekannt",
          Haushalte: "Haushalte",
          Gewerbe: "Gewerbe",
          Kmu: "Kleine und mittlere Unternehmen",
          Grossunternehmen: "Grossunternehmen",
          OeffentlicheHand: "Öffentliche Hand",
          EVU: "Energieversorger",
          BkwIntern: "Intern",
        },
      },
      SAVE: "Speichern",
      REJECT: "Verwerfen",
      SUCCESS_NOTIFICATION: "Organisationsdaten erfolgreich gespeichert",
      DISCARD_SUCCESS_NOTIFICATION: "Organisationsdaten erfolgreich verworfen",
      ERROR_NOTIFICATION: "Organisationsdaten konnten nicht gespeichert werden",
    },
    PERMISSIONS: {
      TEXT: "Service(s) auswählen",
      SAVE: "Speichern",
      SUCCESS_NOTIFICATION: "Berechtigungen erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Berechtigungen konnten nicht gespeichert werden",
    },
    PERMISSIONS_SUMMARY: {
      NO_PERMISSIONS: "Keine Berechtigungen",
    },
    SAVE_DETAILS_DIALOG: {
      TITLE: "Änderungen speichern?",
      REJECT: "Verwerfen",
      CONFIRM: "Jetzt bestätigen",
    },
    SAVE_PERMISSONS_DIALOG: {
      TITLE: "Änderungen speichern?",
      REJECT: "Verwerfen",
      CONFIRM: "Jetzt bestätigen",
    },
    CREATE: {
      TITLE: "Neue Organisation",
      BACK_TO_OVERVIEW: "« zurück zur Übersicht",
      CONTINUE: "Weiter",
      ABORT: "Abbrechen",
      BACK: "Zurück",
      CONFIRM: "Jetzt bestätigen",
      STEP_SUMMARY: {
        TITLE: "Bestätigung",
      },
      SUCCESS_NOTIFICATION: "Organisation erfolgreich erstellt",
    },
    ASSIGNED_USERS: {
      FIRSTNAME: "Vorname",
      LASTNAME: "Nachname",
      EMAIL: "E-Mail Adresse",
      INVALID_USERS_WARNING: "Achtung, dieser Firma sind ungültige Personen zugewiesen. Diese sind rot gekennzeichnet in der Liste aufgeführt und sollten bereinigt werden.",
    },
  },
  MANAGE_INFO_BANNER: {
    TITLE: "Info-Banner verwalten",
    OVERVIEW: {
      EDIT_INFO_BANNER: "Info-Banner bearbeiten",
      DELETE_INFO_BANNER: "Info-Banner löschen",
      CREATE_INFO_BANNER: "Info-Banner erstellen",
      COPY_INFO_BANNER: "Info-Banner kopieren",
      DELETE_INFO_BANNER_SUCCESS: "Info-Banner erfolgreich gelöscht",
      SERVICES: "Services",
      ALL_SERVICES: "Global: für alle Services",
      MESSAGE: "Meldung",
      START_DATE: "Von",
      END_DATE: "Bis",
      ACTION: "Aktion",
    },
    DETAILS: {
      TITLE: "Info-Banner bearbeiten",
      ID: "Technischer Schlüssel",
      SERVICE: "Betroffener Service",
      TYPE: {
        SERVICE: "Nur Service betroffen",
        CATEGORY: "Service Category betroffen",
      },
      CATEGORY: "Service Category",
      MESSAGE_DE: "Info-Banner-Text Deutsch",
      MESSAGE_FR: "Info-Banner-Text Französisch",
      MESSAGE_EN: "Info-Banner-Text Englisch",
      MESSAGE_IT: "Info-Banner-Text Italienisch",
      START_DATE: "Info-Banner sichtbar vom",
      START_TIME: "ab",
      END_DATE: "Info-Banner sichtbar bis am",
      END_TIME: "bis",
      ERROR_DATE_VALIDATION: "Enddatum muss nach dem Startdatum liegen oder leer sein.",
      ERROR_DATE_FORMAT_VALIDATION: "Datumsformat muss \"23.01.2022\" sein",
      ERROR_TIME_VALIDATION: "Zeitformat muss \"00:00\" sein",
      CANCEL: "Abbrechen",
      SAVE: "Speichern",
      SUCCESS_NOTIFICATION: "Info-Banner erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Info-Banner konnte nicht gespeichert werden",
      WARN_NOTIFICATION: "Achtung: Es sind bereits Info-Banner für die Kategorie dieses Services erfasst!",
      SERVICES_BUTTON: "Service Zuweisung verwalten",
      LOGIN_CHECKBOX: "Auf Loginseite anzeigen",
      SERVICES: {
        TITLE: "Zuweisung verwalten",
        INTRO: "Service(s) auswählen",
        UPDATE: "Aktualisieren",
        ABOARD: "Abbrechen",
        SELECT_ALL_SERVICES: "Alle Services auswählen",
      },
      SERVICES_SUMMARY: {
        TITLE: "Aktiv für folgende Services",
        NONE: "Keine Service ausgewählt.",
      },
    },
    DELETE_INFO_BANNER_DIALOG: {
      TEXT: "Info-Banner wirklich löschen?",
    },
  },
  MANAGE_CC_MAINTENANCE: {
    TITLE: "Kundencenter Wartung verwalten",
    OVERVIEW: {
      EDIT_CC_MAINTENANCE: "Kundencenter Wartung bearbeiten",
      DELETE_CC_MAINTENANCE: "Kundencenter Wartung löschen",
      CREATE_CC_MAINTENANCE: "Kundencenter Wartung erstellen",
      COPY_CC_MAINTENANCE: "Kundencenter Wartung kopieren",
      DELETE_CC_MAINTENANCE_SUCCESS: "Kundencenter Wartung erfolgreich gelöscht",
      MESSAGE: "Meldung",
      START_DATE: "Von",
      END_DATE: "Bis",
      ACTION: "Aktion",
      CUSTOMER_CENTER: "Kundencenter",
    },
    DETAILS: {
      TITLE: "Kundencenter Wartung bearbeiten",
      ID: "Technischer Schlüssel",
      CATEGORY: "Service Category",
      MESSAGE_DE: "Kundencenter Wartung-Text Deutsch",
      MESSAGE_FR: "Kundencenter Wartung-Text Französisch",
      MESSAGE_EN: "Kundencenter Wartung-Text Englisch",
      MESSAGE_IT: "Kundencenter Wartung-Text Italienisch",
      START_DATE: "sichtbar vom",
      START_TIME: "ab",
      END_DATE: "sichtbar bis am",
      END_TIME: "bis",
      ERROR_DATE_VALIDATION: "Enddatum muss nach dem Startdatum liegen oder leer sein.",
      ERROR_DATE_FORMAT_VALIDATION: "Datumsformat muss \"23.01.2022\" sein",
      ERROR_TIME_VALIDATION: "Zeitformat muss \"00:00\" sein",
      CANCEL: "Abbrechen",
      SAVE: "Speichern",
      SUCCESS_NOTIFICATION: "Kundencenter Wartung erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Kundencenter Wartung konnte nicht gespeichert werden",
      DIALOG_SHOWLOGIN: "Kundencenter Wartung auch auf der Loginseite anzeigen",
      CUSTOMER_CENTER: "Kundencenter",
    },
    DELETE_CC_MAINTENANCE_DIALOG: {
      TEXT: "Kundencenter Wartung wirklich löschen?",
    },
  },
  MANAGE_MAINTENANCE: {
    TITLE: "Wartungsmeldungen verwalten",
    OVERVIEW: {
      EDIT_MAINTENANCE: "Meldung bearbeiten",
      DELETE_MAINTENANCE: "Meldung löschen",
      CREATE_MAINTENANCE: "Meldung erstellen",
      COPY_MAINTENANCE: "Meldung kopieren",
      DELETE_MAINTENANCE_SUCCESS: "Wartungsmeldung erfolgreich gelöscht",
      SERVICES: "Services",
      MESSAGE: "Meldung",
      START_DATE: "Von",
      END_DATE: "Bis",
      ACTION: "Aktion",
    },
    DETAILS: {
      TITLE: "Wartungsmeldung bearbeiten",
      ID: "Technischer Schlüssel",
      SERVICE: "Betroffener Service",
      SHOWLOGIN: "HINWEIS: Die Meldung wird auf der Loginseite angezeigt",
      DIALOG_SHOWLOGIN: "Wartungsmeldung auch auf der Loginseite anzeigen",
      TYPE: {
        SERVICE: "Nur Service betroffen",
        CATEGORY: "Service Category betroffen",
      },
      CATEGORY: "Service Category",
      MESSAGE_DE: "Meldungstext Deutsch",
      MESSAGE_FR: "Meldungstext Französisch",
      MESSAGE_EN: "Meldungstext Englisch",
      MESSAGE_IT: "Meldungstext Italienisch",
      START_DATE: "Meldung sichtbar vom",
      START_TIME: "ab",
      END_DATE: "Meldung sichtbar bis am",
      END_TIME: "bis",
      ERROR_DATE_VALIDATION: "Enddatum muss nach dem Startdatum liegen oder leer sein.",
      ERROR_DATE_FORMAT_VALIDATION: "Datumsformat muss \"23.01.2022\" sein",
      ERROR_TIME_VALIDATION: "Zeitformat muss \"00:00\" sein",
      CANCEL: "Abbrechen",
      SAVE: "Speichern",
      SUCCESS_NOTIFICATION: "Wartungsmeldung erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Wartungsmeldung konnte nicht gespeichert werden",
      WARN_NOTIFICATION: "Achtung: Es sind bereits Meldungen für die Kategorie dieses Services erfasst!",
      SERVICES_BUTTON: "Service Zuweisung verwalten",
      LOGIN_CHECKBOX: "Auf Loginseite anzeigen",
      SERVICES: {
        TITLE: "Zuweisung verwalten",
        INTRO: "Service(s) auswählen",
        UPDATE: "Aktualisieren",
        ABOARD: "Abbrechen",
      },
      SERVICES_SUMMARY: {
        TITLE: "Aktiv für folgende Services",
        NONE: "Keine Service ausgewählt.",
      },
    },
    DELETE_MAINTENANCE_DIALOG: {
      TEXT: "Wartungsmeldung wirklich löschen?",
    },
  },
  MANAGE_SERVICE_BUNDLE: {
    TITLE: "Service Bundles verwalten",
    LABEL_FILTER: "Filter",
    HINT_FILTER: "Bundles welche in Namen oder Code die Eingabe beinhalten oder deren Services und Permissions.",
    EDIT_MODE: "Editiermodus",
    EDIT_TREE: {
      CREATE_SERVICE_BUNDLE: "Bundle anlegen",
      HINT_EDIT: "Service Attribute bearbeiten",
      HINT_ADD_SERVICE: "Service unterhalb anlegen",
      HINT_ADD_PERMISSION: "Service Permission unterhalb anlegen",
      BUTTON_SAVE: "Änderungen speichern",
      SAVE: "Speichern",
      LABEL_WARNING: "Ja, ich bin mir bewusst, dass meine Änderungen grosse Auswirkungen für Organisationen und Benutzer haben.",
      LABEL_WARNING_WIKI: "Siehe Wiki: OP Berechtigungen und Bundles",
      MSG_EDIT_SUCCESS: "Service {{serviceCode}} erfolgreich gespeichert.",
      MSG_CREATE_PARENT_FAILED: "Service konnte nicht zum Parent hinzugefügt werden.",
      MSG_EDIT_CANCEL: "Änderungen verworfen.",
      MSG_TREE_SAVE_SUCCESS: "Bundles erfolgreich gespeichert... ({{addCount}} hinzugefügt, {{removeCount}} entfernt)",
      MSG_TREE_SAVE_ERROR: "Bundles konnten nicht vollständig gespeichert werden. Bitte überprüfe den Zustand!",
    },
    EDIT: {
      TITLE: "Service Attribute",
      PARENT: "gehört zur Bundle-Gruppe",
      PARENT_HINT: "Die Bundle-Gruppe wird nur für die gruppierte Darstellung verwendet!",
      CODE: "Code",
      CODE_HINT: "Erlaubte Zeichen sind 'A-Z' und '_'. Ein Punkt ist nur für Service-Permissions erlaubt.",
      NAME_DE: "Name Deutsch",
      NAME_FR: "Name Französisch",
      DESCRIPTION_DE: "Beschreibung Deutsch",
      DESCRIPTION_FR: "Beschreibung Französisch",
      TYPE: "Service Type",
      CUSTOMER: "Kundensegement",
      SUB_CUSTOMER_TYPE: "Kundensegement Typ",
      FORCE_OTP: "Zwei-Faktor-Authentifizierung erforderlich",
      REGULATED_MARKET: "Regulierter Markt",
      CANCEL: "Abbrechen",
      CREATE: "Erstellen",
      UPDATE: "Speichern",
      ERROR_SAVE: "Fehler beim speichern.",
      INVALID_CODE: "Service-Code beginnt mit falschem Präfix! Bitte ändern Sie nicht von sich aus den ersten Teil des Codes!",
      INVALID_PERMISSION_CODE: "Code eines Service-Permissions müssen mit einer Servicerolle beginnen, dann einen Punkt besitzen gefolgt von Grossbuchstaben und Underlines.",
      WARN_CODE_NO_BUSINESS_FIELD: "Warnung: Sie erstellen ein Service-Bundle, welche nicht den Geschäftsfeldern entspricht! Erlaubt, aber unschön! <br/><br/>Siehe Namenskonventionen zu Service-Bundles unter <a href='https://bkwag.atlassian.net/wiki/spaces/AD/pages/311958845/OP+-+Berechtigungen+und+Bundles#OP-BerechtigungenundBundles-NamingConvention' target='_blank' rel='noopener noreferrer'>Wiki-Seite mit Bundles-Namenskonventionen</a>",
    },
    BUNDLE_GROUPS: {
      TITLE: "Alle Bundle-Gruppen",
      CREATE_BUNDLE_GROUP: "Bundle-Gruppe anlegen",
      MSG_CHANGE_PARENT_FAILED: "Service konnte nicht zur Bundle-Gruppe hinzugefügt werden.",
    },
    INACTIVE_SERVICES: {
      TITLE: "Inaktive Bundles aus anderen Brands",
      NAME: "Name",
      CODE: "Code",
      SERVICE_ID: "Service ID",
      ACTIONS: "Aktionen",
      ACTIVATE: "für den aktuellen Brand aktivieren",
      DEACTIVATE: "Service inkl. darunterliegende Services deaktivieren",
      DEACTIVATE_CONFIRM: "Sind Sie wirklich sicher, dass Sie den Service deaktivieren wollen? Damit erhalten Kunden keinen Zugriff mehr auf diesen Service!",
      NOTIFICATION_SUCCESS_ACTIVATE: "Service wurde erfolgreich für den aktuellen Brand / Context aktiviert. Bitte laden Sie die Seite neu, um den Service verwenden zu können.",
      NOTIFICATION_ERROR_ACTIVATE: "Der Service konnte nicht aktiviert werden. Bitte wenden Sie sich an den Administrator oder versuchen Sie es später nochmals.",
      NOTIFICATION_SUCCESS_DEACTIVATE: "Service wurde erfolgreich deaktiviert. Bitte laden Sie die Seite neu.",
      NOTIFICATION_ERROR_DEACTIVATE: "Der Service konnte nicht deaktiviert werden. Bitte wenden Sie sich an den Administrator oder versuchen Sie es später nochmals.",
      RELOAD: "Seite neu laden",
    },
  },
  MANAGE_SERVICE_CONFIGURATION: {
    TITLE: "Service-Konfigurationen verwalten",
    OVERVIEW: {
      EDIT_SERVICE_CONFIGURATION: "Service-Konfiguration bearbeiten",
      DELETE_SERVICE_CONFIGURATION: "Service-Konfiguration löschen",
      CREATE_SERVICE_CONFIGURATION: "Service-Konfiguration erstellen",
      DELETE_SERVICE_CONFIGURATION_SUCCESS: "Service-Konfiguration erfolgreich gelöscht",
    },
    DETAILS: {
      TITLE: "Service-Konfiguration bearbeiten",
      REALM: "Realm",
      ID: "Technischer Schlüssel",
      CATEGORY: "Kategorie",
      LABEL: "Name",
      NAME_DE: "Name Deutsch",
      NAME_FR: "Name Französisch",
      NAME_EN: "Name Englisch",
      NAME_IT: "Name Italienisch",
      URL: "URL",
      URL_DE: "URL Deutsch",
      URL_FR: "URL Französisch",
      URL_EN: "URL Englisch",
      URL_IT: "URL Italienisch",
      ICON_NAME: "Icon-Name",
      FONT_SIZE: "Schriftgrösse",
      ICON_SCALING: "Icon-Scaling",
      NOTIFICATION: "Benachrichtigung",
      ROLES: "Zugeordnete Benutzerrollen",
      ROLES_HINT: "Um mehrere Rollen zu definieren, Leerzeichen als Trennzeichen verwenden",
      ACTION: "Aktion",
      CANCEL: "Abbrechen",
      SAVE: "Speichern",
      SUCCESS_NOTIFICATION: "Service-Konfiguration erfolgreich gespeichert",
      ERROR_NOTIFICATION: "Service-Konfiguration konnte nicht gespeichert werden",
    },
    DELETE_SERVICE_CONFIGURATION_DIALOG: {
      TEXT: "Service-Konfiguration wirklich löschen?",
    },
  },
  MANAGE_NEWS: {
    TITLE: "News verwalten",
    OVERVIEW: {
      EDIT_NEWS: "News-Meldung bearbeiten",
      DELETE_NEWS: "News-Meldung löschen",
      CREATE_NEWS: "News-Meldung erstellen",
      COPY_NEWS: "News-Meldung kopieren",
      DELETE_NEWS_SUCCESS: "News-Meldung erfolgreich gelöscht",
      SERVICES: "Services",
      ALL_SERVICES: "Global: für alle Services",
      PERSONALIZED: "Personalisierte Meldung",
      MESSAGE: "News",
      START_DATE: "Von",
      END_DATE: "Bis",
      ACTION: "Aktion",
    },
    DETAILS: {
      TITLE: "News-Meldung bearbeiten",
      ID: "Technischer Schlüssel",
      SERVICE: "Betroffener Service",
      TYPE: {
        GLOBAL: "Global - alle betroffen",
        CATEGORY: "Service Category betroffen",
      },
      CATEGORY: "Service Category",
      TITLE_DE: "Titel Deutsch",
      TITLE_FR: "Titel Französisch",
      TITLE_EN: "Titel Englisch",
      TITLE_IT: "Titel Italienisch",
      MESSAGE_DE: "Meldungstext Deutsch",
      MESSAGE_FR: "Meldungstext Französisch",
      MESSAGE_EN: "Meldungstext Englisch",
      MESSAGE_IT: "Meldungstext Italienisch",
      START_DATE: "Meldung sichtbar vom",
      START_TIME: "ab",
      END_DATE: "Meldung sichtbar bis am",
      END_TIME: "bis",
      ERROR_DATE_VALIDATION: "Enddatum muss nach dem Startdatum liegen oder leer sein.",
      ERROR_DATE_FORMAT_VALIDATION: "Datumsformat muss \"23.01.2022\" sein",
      ERROR_TIME_VALIDATION: "Zeitformat muss \"00:00\" sein",
      COMMON_PERSON_IDS: "Verknüpfte Personen",
      ACCOUNT_EMAIL: "Login-Email",
      DIRECT_LINK_DE: "Direktlink Deutsch",
      DIRECT_LINK_TEXT_DE: "Direktlink Text Deutsch",
      DIRECT_LINK_FR: "Direktlink Französisch",
      DIRECT_LINK_TEXT_FR: "Direktlink Text Französisch",
      DIRECT_LINK_EN: "Direktlink Englisch",
      DIRECT_LINK_TEXT_EN: "Direktlink Text Englisch",
      DIRECT_LINK_IT: "Direktlink Italienisch",
      DIRECT_LINK_TEXT_IT: "Direktlink Text Italienisch",
      SHOW_OVERLAY: "Meldung mit Overlay hinzufügen",
      SHOW_NO_OVERLAY: "Nur Meldung mit Direktlink",
      OVERLAY: {
        HINT: "Für die Texte im Overlay können Markups verwendet werden, um den Text entsprechend stylen zu können, z.B. Aufzählungen etc. Details siehe ",
        TRY_OVERLAY: "Als Overlay ansehen",
        EXAMPLE_OVERLAY_TITLE: "Beispiel für Inhalt im Overlay",
        TITLE_DE: "Titel Overlay Deutsch",
        TITLE_FR: "Titel Overlay Französisch",
        TITLE_EN: "Titel Overlay Englisch",
        TITLE_IT: "Titel Overlay Italienisch",
        TEXT_DE: "Text Overlay Deutsch",
        TEXT_FR: "Text Overlay Französisch",
        TEXT_EN: "Text Overlay Englisch",
        TEXT_IT: "Text Overlay Italienisch",
        LINK_DE: "Link auf deutsche Webseite",
        LINK_FR: "Link auf französische Webseite",
        LINK_EN: "Link auf englisch Webseite",
        LINK_IT: "Link auf italiensch Webseite",
        LINK_TEXT_DE: "Link Text Deutsch",
        LINK_TEXT_FR: "Link Text Französisch",
        LINK_TEXT_EN: "Link Text Englisch",
        LINK_TEXT_IT: "Link Text Italiensch",
        DOWNLOAD_LINK_DE: "Link auf deutsches Dokument",
        DOWNLOAD_LINK_FR: "Link auf französisches Dokument",
        DOWNLOAD_LINK_EN: "Link auf englisches Dokument",
        DOWNLOAD_LINK_IT: "Link auf italienisches Dokument",
        DOWNLOAD_LINK_TEXT_DE: "Link Text Deutsch für Dokument",
        DOWNLOAD_LINK_TEXT_FR: "Link Text Französisch für Dokument",
        DOWNLOAD_LINK_TEXT_EN: "Link Text Englisch für Dokument",
        DOWNLOAD_LINK_TEXT_IT: "Link Text Italienisch für Dokument",
        DOWNLOAD_LINK_HINT: "Dateien können auch aufs typo3 hochgeladen werden und mit 'https://www.bkw.ch/fileadmin/oneportal/evup/' hier referenziert werden.",
      },
      CANCEL: "Abbrechen",
      SAVE: "Speichern",
      SUCCESS_NOTIFICATION: "News-Meldung erfolgreich gespeichert",
      ERROR_NOTIFICATION: "News-Meldung konnte nicht gespeichert werden",
      WARN_NOTIFICATION: "Achtung: Es sind bereits Meldungen für die Kategorie dieses Services erfasst!",
      SERVICES_BUTTON: "Service Zuweisung verwalten",
      SERVICES: {
        TITLE: "Zuweisung verwalten",
        INTRO: "Service(s) auswählen",
        UPDATE: "Aktualisieren",
        ABOARD: "Abbrechen",
      },
      SERVICES_SUMMARY: {
        TITLE: "Aktiv für folgende Services",
        NONE: "Keine Services ausgewählt",
      },
      USER_HISTORY: {
        TITLE: "User History",
        DATE: "Datum der Statusänderung",
        MAPPED: "gemappt:",
        STATUS: {
          IGNORE: "ignoriert",
          NEW: "neu (User gemappt)",
          READ: "gelesen",
          LOAD: "dem User angezeigt",
          NO_INTEREST: "kein Interesse",
        },
      },
    },
    CONFIRM_ALL_SERVICES: {
      TITLE: "ACHTUNG: Unbundling Gesetz!",
      TEXT: "Darf dieser Inhalt auch für CRM-Netzkunden aufgeschalten werden? Enthält der Inhalt der News-Meldung KEINE WERBUNG und verletzt das Unbundling-Gesetz nicht?",
      CANCEL: "Abbrechen",
      CONFIRM: "Bestätigen",
    },
    DELETE_NEWS_DIALOG: {
      TEXT: "News-Meldung wirklich löschen?",
    },
  },
  UNAUTHORIZED: {
    TITLE: "Zugang verweigert",
    TEXT: "Sie verfügen nicht über die erforderlichen Berechtigungen um diese Anwendung verwenden zu dürfen.",
    BACK_TO_ACCOUNT: "Zurück zum Kundencenter",
  },
  B2B_EXPORT: {
    TITLE: "B2B Export",
    EXPORT: "Export",
    SUCCESS_NOTIFICATION: "B2B Export erfolgreich ausgelöst. Der Export kann in spätestens 10 Minuten über <a href=\"{{documentsAdminUrl}}\" target=\"_blank\">Dokumente Admin</a> abgerufen werden.",
    ERROR_NOTIFICATION: "Fehler beim Exportieren der B2B Daten",
  },
  DELETE_DIALOG: {
    TITLE: "Löschung bestätigen",
    CANCEL: "Abbrechen",
    CONFIRM: "Bestätigen",
  },
  GENERAL_ERROR: "Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  CH: "Schweiz",
  male: "Herr",
  female: "Frau",
  de: "Deutsch",
  fr: "Französisch",
  en: "Englisch",
  YES: "Ja",
  NO: "Nein",
  EMPTY_BUNDLE_GROUP: "Keine Bundle-Gruppe",
};
