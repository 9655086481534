import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { Service as OpIamAdminService } from "../../generated/op-iam-admin/v1/model/service";
import { ServiceDisplay } from "../model/service-display";
import { ServiceCodeGroup } from "../model/service-code-group";
import { OpIamAdminApiService } from "./op-iam-admin-api.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { ServiceTypeEnum } from "../../generated/op-iam-admin/v1/model/serviceTypeEnum";
import { Store } from "@ngrx/store";
import { AppState } from "../../store/app.state";
import { selectBundleGroups } from "../../store/app.selector";
import { GlobalServiceDisplayService } from "./global-service-display.service";

@Injectable()
export class ServiceBundleService extends OpIamAdminApiService {

  private readonly baseUrl: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/servicebundles`;

  constructor(private bfcConfigurationService: BfcConfigurationService,
    protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService,
    private httpClient: HttpClient,
    private store: Store<AppState>,
  ) {
    super(bfcNotificationService, bfcTranslationService);
  }

  private readonly roleNameB2BAdmin: string = "ROLE_B2B_ADMIN";

  /**
   * add services which are in the 'allowedServices' group, but should not be visible!
   * @private
   */
  private readonly IGNORE_SERVICES: String[] = ["ROLE_OP_SUPER_USER"];

  private cachedBundleGroups: OpIamAdminService[] = [];

  private cachedBundles: Observable<Map<string, ServiceDisplay>>;

  private cachedInactiveBundles: Observable<OpIamAdminService[]>;

  filterByIgnoredServices(services: OpIamAdminService[]): OpIamAdminService[] {
    return services.filter(service => !this.IGNORE_SERVICES.includes(service.code));
  }

  /**
     * Load / return cached map of all services and bundles as map
     * @return Map<string, ServiceDisplay> - eg ROLE_FOOBAR: {code:'ROLE_FOOBAR', name: 'FooBar Service'}
     */
  getAllServicesAndBundles(): Observable<Map<string, ServiceDisplay>> {
    if (!this.cachedBundles) {
      // GET bundles and memorize them
      const url = `${this.baseUrl}/search/fulltree`;
      this.cachedBundles = this.store.select(selectBundleGroups).pipe(
        tap((bundleGroups) => this.cachedBundleGroups = bundleGroups),
        switchMap(() => this.httpClient.get<OpIamAdminService[]>(url)),
        map(services => this.buildServiceDisplayMap(services)),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(() => EMPTY),
      );
    }
    return this.cachedBundles;
  }

  /**
     * Get all Services and Bundles with filtered by the given service codes (role)
     * @param filteredByCodes - aka roles, can be null when no filtering wanted
     * @return all matching services, bundles and bundle groups
     */
  getAllServiceOrMatchingBundlesByCodes(filteredByCodes: string[]): Observable<Map<string, ServiceDisplay>> {
    return this.getAllServicesAndBundles().pipe(
      map(serviceMap => {
        return this.filterByServiceCodes(filteredByCodes, serviceMap);
      }),
    );
  }

  getAllNonB2BAdminBundlesAndServices(): Observable<Map<string, ServiceDisplay>> {
    return this.getAllServicesAndBundles().pipe(
      map(serviceMap => this.filterByNonB2BAdminCodes(serviceMap)),
    );
  }

  /**
   * Get all Services and Bundles filtered by service Types = {bundles, organisations, permissions}
   * @return all matching services, bundles and bundle groups
   */
  getAllBundlesAndOrganisationServices(): Observable<Map<string, ServiceDisplay>> {
    let allowedServiceTypes: ServiceTypeEnum[] = [];
    allowedServiceTypes.push(ServiceTypeEnum.SERVICE_BUNDLE);
    allowedServiceTypes.push(ServiceTypeEnum.ORGANISATION);
    allowedServiceTypes.push(ServiceTypeEnum.SERVICE_PERMISSION);
    return this.getAllServicesAndBundles().pipe(
      map(serviceMap => {
        return this.filterByServiceType(allowedServiceTypes, serviceMap);
      }),
    );
  }

  private filterByServiceCodes(filteredByCodes: string[], serviceMap: Map<string, ServiceDisplay>)
    : Map<string, ServiceDisplay> {
    const hasFilter: boolean = filteredByCodes?.length > 0;
    const filteredMap: Map<string, ServiceDisplay> = new Map();
    for (const serviceDisplay of Object.values(serviceMap)) {
      if ( !hasFilter
          || filteredByCodes.includes(serviceDisplay.code)
          || serviceDisplay.type === ServiceTypeEnum.BUNDLE_GROUP
      ) {
        filteredMap[serviceDisplay.code] = serviceDisplay;
      }

      if (serviceDisplay.type === ServiceTypeEnum.SERVICE_BUNDLE && serviceDisplay.childrenCodes) {
        const matchingChilds = serviceDisplay.childrenCodes.filter(
          childCode => !hasFilter || filteredByCodes.includes(childCode),
        );
        if (matchingChilds && matchingChilds.length > 0) {
          // match --> a child code has matched, add bundle to result
          filteredMap[serviceDisplay.code] = serviceDisplay;
        }
      }
    }
    return filteredMap;
  }

  private filterByNonB2BAdminCodes(serviceMap: Map<string, ServiceDisplay>): Map<string, ServiceDisplay> {
    const filteredMap: Map<string, ServiceDisplay> = new Map();
    Object.values(serviceMap)
      .filter(serviceDisplay => !serviceDisplay.code.includes(this.roleNameB2BAdmin))
      .forEach(serviceDisplay => filteredMap[serviceDisplay.code] = serviceDisplay);
    return filteredMap;
  }

  private filterByServiceType(allowedServiceTypes: ServiceTypeEnum[], serviceMap: Map<string, ServiceDisplay>)
    : Map<string, ServiceDisplay> {
    const hasFilter: boolean = allowedServiceTypes?.length > 0;
    const filteredMap: Map<string, ServiceDisplay> = new Map();
    for (const serviceDisplay of Object.values(serviceMap)) {
      if ( (!hasFilter
          || allowedServiceTypes.includes(serviceDisplay.type)
          || serviceDisplay.type === ServiceTypeEnum.BUNDLE_GROUP) &&
          !this.IGNORE_SERVICES.includes(serviceDisplay.code) ) {
        filteredMap[serviceDisplay.code] = serviceDisplay;
      }
    }
    return filteredMap;
  }

  /**
     * Private method used to services list into map with ServiceDisplay values
     * @param services
     */
  private buildServiceDisplayMap(services: OpIamAdminService[]): Map<string, ServiceDisplay> {
    const bundleMap: Map<string, ServiceDisplay> = new Map();
    const bundleGroupResult: string[] = [];

    // 1.) sort by name
    // 1a.) sort bundle groups by name
    this.cachedBundleGroups.sort(ServiceBundleService.compareServicesByName());
    // 1b.) sort services by name
    services.sort(ServiceBundleService.compareServicesByName());

    // make a deep copy to remove results
    let localResultingServices = JSON.parse(JSON.stringify(services));

    // 2.) create a result entry per each bundle-group (having children!)
    this.cachedBundleGroups.forEach(bundleGroup => {
      if (bundleGroup.children?.length > 0) {
        // sort children
        bundleGroup.children.sort(ServiceBundleService.compareServicesByName());
        let currentServiceDisplay: ServiceDisplay = this.getServiceDisplay(bundleGroup, false);
        // add bundle group to result map
        bundleMap[bundleGroup.code] = currentServiceDisplay;

        // add children / bundles to bundle-group:
        bundleGroup.children.forEach(bundleChild => {
          const index = localResultingServices.findIndex(bundle => bundleChild.serviceId === bundle.serviceId);
          if (index !== -1) {
            const bundle = localResultingServices[index];

            if (this.isServiceTypeBundle(bundle.code)) {
              bundleMap[bundle.code] = this.getServiceDisplay(bundle, false);
            }

            // remove from services result
            localResultingServices.splice(index, 1);
          }
        });
      }
    });

    // 3.) add resulting services to displayMap (only bundles without groups and services are left)
    localResultingServices.forEach(service => {
      if (service.serviceType !== ServiceTypeEnum.OPADMIN) {
        let currentServiceDisplay: ServiceDisplay = this.getServiceDisplay(service, false);

        if (this.isServiceTypeBundle(service.code)) { // bundles without bundle-group
          const bundleGroupKey = this.getBundleGroupCode(service.code);
          if (!bundleGroupResult.includes(bundleGroupKey)) {
            bundleGroupResult.push(bundleGroupKey);
            // 3.a) add dummy bundle-group
            bundleMap[bundleGroupKey] = this.getServiceDisplay(service, true);
          }
        }
        // 3.b) add services
        bundleMap[service.code] = currentServiceDisplay;
      }
    });
    return bundleMap;
  }

  private static compareServicesByName() {
    return (s1: OpIamAdminService, s2: OpIamAdminService) => {
      // BUNDLES first
      if (s1.serviceType === ServiceTypeEnum.SERVICE_BUNDLE) {
        if (s2.serviceType === s1.serviceType) {
          return s1?.name?.localeCompare(s2?.name);
        } else {
          return -1; // s1 won!
        }
      } else if (s2.serviceType === ServiceTypeEnum.SERVICE_BUNDLE) {
        return 1;
      }

      const label1 = GlobalServiceDisplayService.getGlobalServiceDisplayName(s1);
      const label2 = GlobalServiceDisplayService.getGlobalServiceDisplayName(s2);
      if (!label1 && !label2) return 0;
      if (label1 > label2 || !label2) return 1; // s1 goes up
      if (label1 < label2 || !label1) return -1; // s1 goes down
      return 0;
    };
  }

  private getServiceDisplay(service: OpIamAdminService, isMakeBundleGroup: boolean): ServiceDisplay {
    const type = isMakeBundleGroup ? ServiceTypeEnum.BUNDLE_GROUP : service.serviceType;
    let code = service.code;
    const id = service.serviceId;
    let label, cssClass, tooltip: string;
    const childrenCodes = service.children ? service.children.map(s => s.code) : undefined;
    const isForceOtp = service.isForceOtp || service?.children?.some(s => s.isForceOtp);
    switch (type) {
      case ServiceTypeEnum.BUNDLE_GROUP:
        let bundleGroupCode = service.code;
        let bundleGroup = service;
        if (isMakeBundleGroup) {
          // service is a child, not directly a bundlegroup
          bundleGroupCode = this.getBundleGroupCode(code);
          bundleGroup = this.cachedBundleGroups.find(group => group.code == bundleGroupCode);
        }
        code = bundleGroupCode;
        label = !!bundleGroup ? bundleGroup.name : this.bfcTranslationService.translate("EMPTY_BUNDLE_GROUP");
        cssClass = "bundle-group-checkbox";
        break;
      case ServiceTypeEnum.SERVICE_BUNDLE:
        label = this.getBundleGroupName(service)[1];
        cssClass = "bfc-margin-left-3";
        tooltip = service.code; // add bundle code
        if (childrenCodes) {
          tooltip += " = \n [ \n";
          // add service codes into bundle brackets
          tooltip += service.children.map(bundleChildService => {
            if (bundleChildService.children && bundleChildService.children.length > 0) {
              return bundleChildService.code +
                                // add service permissions into service brackets
                                " ( \n   -  " + bundleChildService.children.map(servicePermission => servicePermission.code.substr(bundleChildService.code.length + 1)).join(",\n  -  ") + ")";
            }
            return bundleChildService.code;
          }).join(",\n");
          tooltip += "\n ]";
        }
        break;
      default:
        label = GlobalServiceDisplayService.getGlobalServiceDisplayName(service);
        tooltip = service.code;
        break;
    }
    return {
      id: id,
      code: code,
      isForceOtp: isForceOtp,
      name: service.name,
      label: label,
      tooltip: tooltip,
      type: type + "",
      childrenCodes: childrenCodes,
      cssClass: cssClass,
    };
  }

  private getBundleGroupCode(serviceBundleCode: string): string {
    let bundleGroupCode: string;
    const bundle = this.cachedBundleGroups
      .find(group => group.children.some(service => service.code == serviceBundleCode));
    if (!!bundle) {
      bundleGroupCode = bundle.code;
    } else {
      // fallback to logic with last "_"
      bundleGroupCode = serviceBundleCode.substring(0, serviceBundleCode.lastIndexOf("_"));
    }
    return bundleGroupCode;
  }

  /**
   * Split name by ' - ' if possible to define group name label [0] / and bundle label [1]
   * @param service - bundle service
   * @return string[] with labels for bundleGroup and bundle (or fallback: input twice)
   */
  private getBundleGroupName(service: OpIamAdminService): string[] {
    let name = service.name;
    if (name && name.length > 0) {
      const result = name.split(" - ", 2);
      if (result.length > 1) {
        return result;
      }
    }
    name = GlobalServiceDisplayService.getGlobalServiceDisplayName(service);
    // fallback return name twice
    return [name, name];
  }

  /**
     * Checks type by naming convention. See Wiki: https://bkwiki.bkw-fmb.ch/display/AD/OP+Berechtigungen+und+Bundles#Naming_Convention
     * @param serviceCode
     * @return true if string starts with BUNDLE_
     */
  isServiceTypeBundle(serviceCode: string) {
    return serviceCode.startsWith("BUNDLE_");
  }

  /**
     * Extend group bundles to display "bundle group name"
     * @param serviceCodes
     */
  extendBundleGroupCodes(serviceCodes: string[]) {
    return this.codeGroupsToCodes(this.groupCodes(serviceCodes));
  }

  /**
     * Sort and extend group bundles to display
     */
  extendBundleGroupCodesSorted(serviceCodes: string[]): Observable<string[]> {
    return this.getAllBundlesAndOrganisationServices().pipe(
      catchError(() => of({})), // the list cant be sorted ... 【• ▂ •】
      map((serviceBundleMap: Map<string, ServiceDisplay>): string[] => 
        this.codeGroupsToCodes(this.sortCodeGroupsByLabel(this.groupCodes(serviceCodes), serviceBundleMap)),
      ),
    );
  }

  extendBundlesAndOrganisationServicesGroupCodesSorted(serviceCodes: string[]): Observable<string[]> {
    return this.getAllBundlesAndOrganisationServices().pipe(
      catchError(() => of({})), // the list cant be sorted ... 【• ▂ •】
      map((serviceBundleMap: Map<string, ServiceDisplay>): string[] =>
        this.codeGroupsToCodes(this.sortCodeGroupsByLabel(this.groupCodes(serviceCodes), serviceBundleMap)),
      ),
    );
  }

  activateServiceForContext(serviceId: number): Observable<void> {
    const url: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/services/${serviceId}/activate`;
    return this.httpClient.post<void>(url, null).pipe(
      this.catchHttpError(),
    );
  }

  deactivateServiceForContext(serviceId: number): Observable<void> {
    const url: string = `${this.bfcConfigurationService.configuration.backendSettings.iamAdminApiUrl}/v1/services/${serviceId}/deactivate`;
    return this.httpClient.post<void>(url, null).pipe(
      this.catchHttpError(),
    );
  }

  /**
     * Load / return cached map of for actual context inactive services and bundles as service array
     * @return
     */
  getInactiveServicesAndBundles(): Observable<OpIamAdminService[]> {
    if (!this.cachedInactiveBundles) {
      // GET bundles and memorize them
      const url = `${this.baseUrl}/search/othersfulltree`;
      this.cachedInactiveBundles = this.httpClient.get<OpIamAdminService[]>(url).pipe(
        map(services => {
          return services.filter( service => service.serviceType !== ServiceTypeEnum.OPADMIN);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(() => {
          delete this.cachedInactiveBundles;
          return EMPTY;
        }));
    }
    return this.cachedInactiveBundles;
  }

  /**
     * Group codes by bundle
     */
  private groupCodes(serviceCodes: string[]): ServiceCodeGroup[] {
    const codeGroups: ServiceCodeGroup[] = [];

    serviceCodes.forEach(code => {
      if (this.isServiceTypeBundle(code)) {
        const groupBundleCode = this.getBundleGroupCode(code);
                
        let parent = codeGroups.find(group => group.code === groupBundleCode);
        // the code must be grouped --> do we already have a bundle parent?
        if (!parent) {
          parent = { code: groupBundleCode, children: [] };
          codeGroups.push(parent);
        }
        // add as child
        parent.children.push({ code, children: [] });

      } else {
        // normal (not grouped) service
        codeGroups.push({ code, children: [] });
      }
    });

    return codeGroups;
  }

  /**
     * unwrap code groups to codes
     */
  private codeGroupsToCodes(codeGroups: ServiceCodeGroup[]): string[] {
    // unwrapping the groups back to array 
    return codeGroups
      .map((codeGroup: ServiceCodeGroup): string[] =>
        [codeGroup.code].concat(codeGroup.children.map(child => child.code)))
    // flattening the code[][] to code[]
      .reduce((flat, codeList: string[]): string[] => {
        return flat.concat(codeList);
      }, []);
  }

  /**
     * Sort code groups
     */
  private sortCodeGroupsByLabel(codes: ServiceCodeGroup[], serviceDisplayMap: Map<string, ServiceDisplay>):
  ServiceCodeGroup[] {
    return codes
      .map((codeGroup: ServiceCodeGroup): ServiceCodeGroup => {
        codeGroup.label = serviceDisplayMap[codeGroup.code]?.label;
        return codeGroup;
      })
    // Sorting bundle children by name
      .map((codeGroup: ServiceCodeGroup): ServiceCodeGroup => {
        codeGroup.children = this.sortCodeGroupsByLabel(codeGroup.children, serviceDisplayMap);
        return codeGroup;
      })
    // Sorting service by name
      .sort((a, b) => {
        const labelA = a?.label?.toLowerCase();
        const labelB = b?.label?.toLowerCase();
        if (!labelA && !labelB) return 0;
        if (labelA > labelB || !labelB) return 1; // a goes up
        if (labelA < labelB || !labelA) return -1; // a goes down
        return 0;
      })
    // Sorting Bundles to the top
      .sort((a, b) => {
        const aHasChildren = a.children.length > 0;
        const bHasChildren = b.children.length > 0;
                
        if (aHasChildren && !bHasChildren) return -1;
        else if (!aHasChildren && bHasChildren) return 1;
        return 0;
      });
  }

}

