import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { EMPTY, OperatorFunction } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class OpIamAdminApiService {
  constructor(protected bfcNotificationService: BfcNotificationService,
    protected bfcTranslationService: BfcTranslationService) {
  }

  catchHttpError<T>(translation?: string): OperatorFunction<T, T> {
    return catchError((error: unknown) => {
      if (error instanceof HttpErrorResponse && error.error) {
        if (error.error.de) {
          this.bfcNotificationService.showNotification({
            type: BfcNotificationType.ERROR,
            message: this.bfcTranslationService.translate(error.error),
          });
          return EMPTY;
        }
      }

      this.bfcNotificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate(translation ? translation : "GENERAL_ERROR"),
      });

      return EMPTY;
    });
  }
}
