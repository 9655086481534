import { RouterModule, Routes } from "@angular/router";
import { AppGuard } from "./app.guard";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
    canActivate: [AppGuard],
  },
  {
    path: "manage-user",
    loadChildren: () => import("./manage-user/manage-user.module").then(m => m.ManageUserModule),
  },
  {
    path: "manage-organisation",
    loadChildren: () => import("./manage-organisation/manage-organisation.module").then(m => m.ManageOrganisationModule),
  },
  {
    path: "b2b-export",
    loadChildren: () => import("./b2b-export/b2b-export.module").then(m => m.B2bExportModule),
  },
  {
    path: "manage-maintenance",
    loadChildren: () => import("./manage-maintenance/manage-maintenance.module").then(m => m.ManageMaintenanceModule),
  },
  {
    path: "manage-info-banner",
    loadChildren: () => import("./manage-info-banner/manage-info-banner.module").then(m => m.ManageInfoBannerModule),
  },
  {
    path: "manage-cc-maintenance",
    loadChildren: () => import("./manage-cc-maintenance/manage-cc-maintenance.module").then(m => m.ManageCCMaintenanceModule),
  },
  {
    path: "manage-service-bundle",
    loadChildren: () => import("./manage-service-bundle/manage-service-bundle.module").then(m => m.ManageServiceBundleModule),
  },
  {
    path: "manage-service-configuration",
    loadChildren: () => import("./manage-service-configuration/manage-service-configuration.module").then(m => m.ManageServiceConfigurationModule),
  },
  {
    path: "manage-news",
    loadChildren: () => import("./manage-news/manage-news.module").then(m => m.ManageNewsModule),
  },
  {
    path: "unauthorized",
    loadChildren: () => import("./unauthorized/unauthorized.module").then(m => m.UnauthorizedModule),
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
