import { Component, OnInit } from "@angular/core";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcTranslationService } from "@bfl/components/translation";
import { AdminPermissionService } from "./core/admin-permission.service";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "./store/app.state";
import { loadApp } from "./store/app.actions";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  // base url for the b2b navigation
  private readonly baseUrl: string = `${window.location.protocol}//${window.location.host}`;

  /**
   * Navigation Tree injected to the b2b navigation (used as in app navigation)
   */
  readonly navigationTree: any = JSON.stringify({
    serviceName: this.bfcTranslationService.translate("SERVICE_NAME"),
    parentUrl: this.baseUrl,
    subNavigationItems: this.createNavigationItems(),
  });

  private createNavigationItems() {
    const navigationItems = [
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_USER.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-user",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_ONEPORTAL_ADMIN"),
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_ORGANISATION.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-organisation",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_ONEPORTAL_ADMIN")
            && this.adminPermissionService.isKonzernOrBrandOrServiceAdmin,
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("B2B_EXPORT.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/b2b-export",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_EXPORT")
            && this.adminPermissionService.isKonzernOrBrandOrServiceAdmin,
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_SERVICE_BUNDLE.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-service-bundle",
        },
        isVisible: (
          this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_SERVICEBUNDLES") ||
            this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_SERVICEBUNDLES_READONLY")
        ) && this.adminPermissionService.isKonzernOrBrandOrServiceAdmin,
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_SERVICE_CONFIGURATION.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-service-configuration",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_SERVICECONFIG"),
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_MAINTENANCE.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-maintenance",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_MAINTENANCE"),
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_INFO_BANNER.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-info-banner",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_MAINTENANCE"),
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_CC_MAINTENANCE.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-cc-maintenance",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_CC_MAINTENANCE"),
        disableAccountActivityCalls: true,
      },
      {
        labelKey: this.bfcTranslationService.translate("MANAGE_NEWS.TITLE"),
        url: {
          base: this.baseUrl,
          path: "/manage-news",
        },
        isVisible: this.bfcAuthenticationService.hasRealmRole("ROLE_B2B_ADMIN_NEWS"),
        disableAccountActivityCalls: true,
      },
    ];

    return navigationItems.filter(item => item.isVisible);
  }

  get enableB2bNavigation(): boolean {
    return !this.bfcConfigurationService.configuration.disableB2bNavigation;
  }

  constructor(private router: Router,
    private bfcTranslationService: BfcTranslationService,
    private bfcAuthenticationService: BfcAuthenticationService,
    private bfcConfigurationService: BfcConfigurationService,
    private adminPermissionService: AdminPermissionService,
    private store: Store<AppState>,
  ) {
  }

  routeRequest(e): void {
    this.router.navigate([e.detail.path]);
  }

  ngOnInit() {
    this.store.dispatch(loadApp());
  }
}
